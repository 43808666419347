<template>
    <el-row class="menu_page">
         <el-col>
             <el-menu
                mode="vertical"
                text-color="#333333"
                active-text-color="#FB5C55"
                class="el-menu-vertical-demo">
                <router-link to="/organizationalmanagement">
                    <el-menu-item index="0">
                        <i class="fa fa-margin el-icon-office-building"></i>
                        <span slot="title">机构管理</span>
                    </el-menu-item>
                </router-link>
                <template  v-for="item in items" >
                    <el-submenu v-if="item.children" :index="item.path" :key="item.path">
                        <template slot="title">
                            <i :class="'fa fa-margin '+item.icon"></i>
                            <span slot="title">{{item.name}}</span>
                        </template>
                        <router-link v-for="(citem,cindex) in item.children"
                            :to="citem.path" :key="cindex">
                            <el-menu-item
                                :index='citem.path'>
                                <span slot="title">{{citem.name}}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>

                </template>
             </el-menu>
         </el-col>
    </el-row>
</template>
<script>
export default {
  name: 'leftmenu',
  data () {
    return {
      items: [
        {
          icon: 'el-icon-edit-outline',
          name: '财务管理',
          path: 'fund',
          children: [
			  { path: 'collectionrecord', name: '收款记录' },
			  { path: 'financialstatistics', name: '财务统计' }
			  ]
        },
        {
          icon: 'el-icon-notebook-1',
          name: '单词库',
          path: 'info',
          children: [
			  { path: 'wordmanagement', name: '单词管理' },
			  { path: 'thesaurusmanagement', name: '词库管理' },
			  ]
        }
      ]
    }
  }
}
</script>
<style scoped>
.menu_page {
  position: fixed;
  top: 71px;
  left: 0;
  min-height: 100%;
 /* background-color: #324057; */
 box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 99;
}
.el-menu {
  border: none;
}
.fa-margin {
  margin-right: 5px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 140px;
  min-height: 400px;
  text-align: left;
}
.el-menu-vertical-demo {
  width: 35px;
}
.el-submenu .el-menu-item {
  min-width: 140px;
}

.hiddenDropdown,
.hiddenDropname {
  display: none;
}
a {
  text-decoration: none;
}
</style>
