<template>
    <div class="head-nav">
       <div class="left">
         <!-- <span class='title'>国家电网三维远程智能维保系统</span> -->
		 生态园区物联网管控平台
       </div>
	   <div class="head_middle" >
		   <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#0A1738" text-color="#fff" active-text-color="#36CFC9">
			 <router-link to="dataMonitoring" class="link">
			 	 <el-menu-item index="1">园区数据监控中心</el-menu-item>
			 </router-link>
			 <div class="line"></div>
			 <el-submenu index="2" >
			     <template slot="title" class="link">园区数据分析中心</template>
				   <router-link to="comprehensiveDataAnalysis" class="link">
				   	 <el-menu-item index="2-1">园区综合数据分析</el-menu-item>
				   </router-link>
			       <router-link to="agriculturalDataAnalysis" class="link">
			       	 <el-menu-item index="2-2">农业数据统计分析</el-menu-item>
			       </router-link>
				   <router-link to="energyDataAnalysis" class="link">
				   	<!-- <el-menu-item index="2-3">能源数据统计分析</el-menu-item> -->
					 <el-menu-item index="2-3">能源管理</el-menu-item>
				   </router-link>
			   </el-submenu>
		     
			 <div class="line"></div>
			 <el-submenu index="3" >
			     <template slot="title" class="link">园区管理</template>
			 		<router-link to="inventoryManagementIndex" class="link">
			 		  <el-menu-item index="3-1">农产品库存管理</el-menu-item>
			 		</router-link>
			       <router-link to="laborIndex" class="link">
			       	 <el-menu-item index="3-2">劳动人员管理</el-menu-item>
			       </router-link>
			   </el-submenu>
		     
			 <div class="line"></div>
			 <router-link to="agriculturalOptimization" class="link">
			 	<el-menu-item index="4">智能控制</el-menu-item>
			 </router-link>
			 
			 <!-- <div class="line"></div>
			 <router-link to="factory3D" class="link">
			 	<el-menu-item index="5">温室大棚三维数字孪生系统</el-menu-item>
			 </router-link> -->
			 
			 <div class="line"></div>
			 <el-submenu index="6" >
			     <template slot="title" class="link">系统运维</template>
			 		<router-link to="equipmentManagement" class="link">
			 		  <el-menu-item index="6-1">设备管理</el-menu-item>
			 		</router-link>
			       <router-link to="userManagement" class="link">
			       	 <el-menu-item index="6-2">用户管理</el-menu-item>
			       </router-link>
			   </el-submenu>
			   
			   <div class="line"></div>
			   <router-link to="factory3D" class="link">
			   	<el-menu-item index="5">温室大棚三维数字孪生系统</el-menu-item>
			   </router-link>
		     
		   </el-menu>
	   </div>
      <div class="right">
		<!-- <div class="time">{{nowTime}}</div> -->
        <div class="userinfo">
          <!-- <img :src="user.avatar" class='avatar' alt="" v-if="user.avatar"> -->
		  <img src="../assets/user_pic.png" class='avatar' alt="" >
          <div class='welcome'>
            <p class='name avatarname'>{{user}}</p>
          </div>
          <span class='username'>
                        <el-dropdown
                          trigger="click"
                          @command='setDialogInfo'>
                            <span class="el-dropdown-link">
                                <i class="el-icon-caret-bottom el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <!-- <el-dropdown-item command='info'>个人信息</el-dropdown-item> -->
                                <el-dropdown-item  command='logout'>退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                     </span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'HeadNav',
  data () {
    return {
		activeIndex: '1',//默认选择第一个
		// user:{
		// 	name:'李女士',
		// 	// avatar:require('../assets/pic_1.png')
		// },
		 nowTime: '',
		 isPdf:false
	}
	},
	props:{
		type:String
	},
  computed: {
    user() {
		// console.log(11111)
		// console.log(this.$store.getters.user)
		if (document.cookie.length>0) {
		 var arr=document.cookie.split('; ');//这里显示的格式需要切割一下自己可输出看下
		 for(var i=0;i<arr.length;i++){
		  var arr2=arr[i].split('=');//再次切割
		  //判断查找相对应的值
		  if(arr2[0]=='styUserName_z'){
		   return arr2[1];//保存到保存数据的地方
		  }
		 }
		}
      // return this.$store.getters.user
    }
  },
  created () {
    this.nowTimes()
  },
  methods: {
    setDialogInfo (cmditem) {
      if (!cmditem) {
        console.log('test')
        this.$message('菜单选项缺少command属性')
        return
      }
      switch (cmditem) {
        case 'info':
          this.showInfoList()
          break
        case 'logout':
          this.logout()
          break
      }
    },
    logout () {
      // // 清除token
      // localStorage.removeItem('eleToken')
      // // 设置vuex store
      // this.$store.dispatch('clearCurrentState')

      // 页面跳转
      this.$router.push('/login')
    },
	nowTimes () {
	  this.timeFormate(new Date())
	  setInterval(this.nowTimes, 1000)
	  this.clear()
	},
	clear () {
	  clearInterval(this.nowTimes)
	  this.nowTimes = null
	},
	// 点击菜单栏选项
	handleSelect(key, keyPath) {
	    console.log(key, keyPath);
	},
	// 显示当前时间（年月日时分秒）
	timeFormate (timeStamp) {
	  const year = new Date(timeStamp).getFullYear()
	  const month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1
	  const date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate()
	  const hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours()
	  const mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes()
	  const ss = new Date(timeStamp).getSeconds() < 10 ? '0' + new Date(timeStamp).getSeconds() : new Date(timeStamp).getSeconds()
	  this.nowTime = year + '-' + month + '-' + date + ' ' + ' ' + hh + ':' + mm + ':' + ss
	},
  }
}
</script>

<style scoped>
  .head-nav {
    width: 100%;
    height: 60px;
    min-width: 600px;
    background: #0A1738;
    color: #fff;
    border-bottom: 1px solid #0A1738;
	position: relative;
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	justify-content: start;
  }
  .left {
    line-height: 60px;
    /* width: 525px; */
	width: 425px;
	font-size: 24px;
	font-family: ZhenyanGB;
	color: #36CFC9;
	margin-left: 30px;
	letter-spacing: 2px;
  }
  .title {
    display: inline-block;
	width: 346px;
	font-size: 24px;
	font-family: PingFang-SC-Bold;
	color: #FFFFFF;
  }
  .right {
	/* width: 480px; */
    line-height: 60px;
    text-align: right;
    /* padding-right: 30px; */
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: absolute;
	right: 30px;
  }
  .time{
	  font-size: 14px;
	  /* margin-right: 40px; */
  }
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
	background-color: white;
	margin-right: 10px;
  }
  .welcome {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    padding: 0 5px;
  }
  .name {
    line-height: 20px;
    text-align: center;
    font-size: 14px;
  }
  .comename {
    font-size: 12px;
  }
  .avatarname {
    color:#FFFFFF;
	/* font-size: 18px; */
   /* font-weight: bolder; */
   font-family: PingFang-SC-Medium;
  }
  .username {
    cursor: pointer;
    margin-right: 5px;
  }
  .el-dropdown {
    color: #fff;
  }
  .head_middle{
	  /* width: 35%; */
	  display: flex;
	  align-items: center;
	  margin-left: 79px;
  }
  .name{
	  font-size: 14px;
	   border: none;
	  
  }
  .name:hover{
	  border: none;
	  outline: none;
  }
  .line{
	 width: 1px;
	 height: 14px;
	 background: #FFFFFF;
	 opacity: 0.6; 
  }
  .link{
	  /* height: 40px; */
	  padding: 0 30px;
  }
  a:-webkit-any-link{
	  text-decoration: none;
  }
  /deep/.el-menu-item{
	  color: rgba(255,255,255,1);
	  padding: 0;
	  font-size: 14px;
	  font-family: PingFang SC;
  }
  /deep/.el-menu.el-menu--horizontal{
	  border: none;
	  background-color: #1D213E;
	  color: rgba(255,255,255,0.4);
	  font-size: 18px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
  }
  /deep/.el-menu-item{
	  height: 40px;
	  line-height: 40px;
  }
  /deep/.el-menu--horizontal>.el-submenu .el-submenu__title{
	  height: 40px;
	  line-height: 40px;
	  color: #FFFFFF;
  }
  /deep/.el-menu--horizontal>.el-submenu{
	  height: 40px;
	  /* line-height: 40px; */
  }
  /deep/.el-menu--horizontal .el-menu-item.is-active{
	  font-weight: bold;
	   color: #36CFC9;
	   border-bottom: 2px solid #36CFC9;
  }
  /deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
	  font-weight: bold;
	   color: #36CFC9;
	   border-bottom: 2px solid #36CFC9;
  }
  /deep/.el-menu--horizontal>.el-submenu:focus, .el-menu--horizontal>.el-submenu:hover{
  	  font-weight: bold;
  	   color: #FFFFFF;
	   background-color: red;
  	   /* border-bottom: 2px solid #00FFFF; */
  }
  /deep/.el-menu--horizontal .el-menu-item:hover{  
  outline: 0 !important;  
  background-color: #1D213E;
  color: rgba(255,255,255);
}  
  /deep/.el-menu--horizontal .el-menu-item:not(.is-disabled):focus{
	  background-color: #1D213E;
  }
</style>
