<template>
<div class="export_operate">
  <div class="el-icon-close close" @click="close"></div>
  <div class="marks_top">
  	<div class="marks_name">{{dialog.title}}</div>
  </div>
  <div class="content">
	  <el-form
	    ref="form"
	    :model="form"
	    :rules="rules"
	  >
	   
	    <el-form-item prop="dw" label="配送单位">
	      <el-select v-model="form.dw" placeholder="请选择配送单位" @change="handleChange">
	          <el-option
	            v-for="item in options"
	            :key="item.value"
	            :label="item.label"
	            :value="item.value"
	      	   popper-class="select_tree"
	      	  >
	          </el-option>
	        </el-select>
	    </el-form-item>
	    
	  </el-form>
  </div>
  <div class="btn">
	  <div class="submit" @click="onSubmit('form')">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;">确认导出</span>
	  </div>
	 <!-- <div class="cancel" @click="close">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;" >取消</span>
	  </div> -->
  </div>
</div>
</template>

<script>
import { excelOutStock } from '@/api/index'
import axios from 'axios'
export default {
  name: 'ExportExcelOperate',
  props: {
    dialog: Object,
	id:String,
  },
  data () {
    return {
		form:{
			dw:'',
			place:''
		},
		rules: {
		          dw: [
		            { required: true, message: '请选择配送单位', trigger: 'change' },
		          ],	  
		        },
		options: [{
		          value: 'xidan',
		          label: '西单'
		        }, {
		          value: 'yingzuo',
		          label: '银座'
		        }, {
		          value: 'weilaicheng',
		          label: '未来城'
		        }, {
		          value: 'baiguanglu',
		          label: '白广路'
		        }, {
		          value: 'ducheng',
		          label: '都城'
		        },{
		          value: 'dianliyiyuan',
		          label: '电力医院'
		        }, {
		          value: 'yingda',
		          label: '英大'
		        }, {
		          value: 'wuzi',
		          label: '物资'
		        }, {
		          value: 'guoji',
		          label: '国际'
		        },{
		          value: 'zhongzai',
		          label: '中再'
		        },{
		          value: 'tonghang',
		          label: '通航'
		        }, {
		          value: 'guokang',
		          label: '国康'
		        }, {
		          value: 'xiju',
		          label: '西局'
		        }],
		      
    }
  },
  mounted () {

  },
  methods: {
	handleChange(value){
		console.log(value);
		let obj = {};
		obj = this.options.find((item)=>{
		    return item.value === value;
		});    
		console.log(obj);
		this.form.place=obj.label
		
	},
	// 关闭
	close(){
		this.dialog.show = false
	}, 
	 // 导出Excel公用方法
	 exportMethod(data) {
	   axios({
	     method: data.method,
	     url: `${data.url}${data.params ? '?' + data.params : ''}`,
	 	responseType: 'blob'
	   }).then((res) => {
	     const link = document.createElement('a')
	     let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
	     link.style.display = 'none'
	     link.href = URL.createObjectURL(blob)
	   
	     // link.download = res.headers['content-disposition'] //下载后文件名
	     link.download = data.fileName //下载的文件名
	     document.body.appendChild(link)
	     link.click()
	     document.body.removeChild(link)
	 	// 操作成功
	 	  this.$message({
	 	    message: '导出成功！',
	 	    type: 'success'
	 	  })
	 	  this.dialog.show = false
	 	  this.$emit('update')
	   
	   }).catch(error => {
	     this.$Notice.error({
	       title: '错误',
	       desc: '网络连接错误'
	     })
	     console.log(error)
	   })
	 },
	onSubmit(formName) {
		console.log(this.id)
	        this.$refs[formName].validate((valid) => {
	          if (valid) {
				console.log('导出Excel')
				let myObj = {
				  method: 'get',
				  url: 'http://gardens.dysense.cn/api/Stock/excelOutStock',
				  fileName: '出库单',
				  params: `id=${this.id}&place=${this.form.place}&key=${this.form.dw}`
				}
				this.exportMethod(myObj)
	          } else {
	            console.log('error submit!!');
	            return false;
	          }
	        });
	      },
	
	
  }
}
</script>

<style scoped>
.export_operate{
  width: 400px;
  height: 260px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: rgba(10, 23, 56, 0.7);
  border: 2px solid #36CFC9;
  text-align: center;
}
 .close{
	 position: absolute;
	 right: 15px;
	 top: 15px;
	 color: #FFFFFF;
	 font-size: 20px;
 }
 .marks_top{
 	width: 100%;
 	height: 77px;
 	border-left: 5px solid #36CFC9;
 	padding:15px 0  0 25px;
 	box-sizing: border-box;
 	/* margin-bottom: 30px; */
 }
 .marks_name{
 	width: 50%;
 	height:48px ;
 	line-height: 48px;
 	background-image: linear-gradient(to right, rgba(54,207,201,0.5) , rgba(255,255,255,0));
 	color: #FFFFFF;
 	font-size: 16px;
 	font-family: PingFang SC;
 	font-weight: bold;
 	text-align: left;
 	padding-left:20px;
 	box-sizing: border-box;
 	letter-spacing: 2px;
 }
 .content{
	 width: 100%;
	 height: 50px;
	 margin-top: 20px;
	 /* background-color: red; */
	 margin-bottom: 30px;
	 padding-left: 30px;
	 box-sizing: border-box;
 }
 .btn{
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
 }
 .submit{
 	  width: 108px;
 	  height: 48px;
 	  background: url(../../assets/back_bg.png) no-repeat center;
 	  background-size: cover;
 	  border: 1px solid #36CFC9;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
 }
 .cancel{
 	  width: 108px;
 	  height: 48px;
 	  background: #0B1326;
 	  border: 1px solid #4B5052;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
 }
 /deep/.el-form{
 	width: 100%!important;
 	position: relative;
 	left: 50%;
 	transform: translateX(-50%);
 } 
 /deep/.el-form-item__label{
 	 height: 48px;
	 line-height: 48px;
 	 width: 80px!important;
 	 color: #FFFFFF;
 	 text-align: justify;
  }
/deep/.el-select{
	/* width: 68%; */
	margin-right: 0;
}
/deep/.el-select .el-input__inner{
	width: 250px!important;
}
</style>
