import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Index from '../views/Index.vue'
// 园区数据监控中心
import DataMonitoring from '../views/ParkDataMonitoringCenter/DataMonitoring.vue'
// import AgriculturalLandDataMonitoring from '../views/ParkDataMonitoringCenter/AgriculturalLandDataMonitoring.vue'

// 园区数据分析中心
// import ComprehensiveDataAnalysis from '../views/DataAnalysisCenter/ComprehensiveDataAnalysis.vue'
// import AgriculturalDataAnalysis from '../views/DataAnalysisCenter/AgriculturalDataAnalysis.vue'
// import EnergyDataAnalysis from '../views/DataAnalysisCenter/EnergyDataAnalysis.vue'

// 园区管理--农产品管理
import InventoryManagementIndex from '../views/ParkManagement/InventoryManagement/index.vue'
import ProductStorage from '../views/ParkManagement/InventoryManagement/ProductStorage.vue'
import ExWarehouse from '../views/ParkManagement/InventoryManagement/ExWarehouse.vue'
import ProductNameManagement from '../views/ParkManagement/InventoryManagement/ProductNameManagement.vue'
import WarehouseManagement from '../views/ParkManagement/InventoryManagement/WarehouseManagement.vue'
import InventoryQuery from '../views/ParkManagement/InventoryManagement/InventoryQuery.vue' 
import WarehousingRecord from '../views/ParkManagement/InventoryManagement/WarehousingRecord.vue' 
import OutboundRecord from '../views/ParkManagement/InventoryManagement/OutboundRecord.vue'
import OutboundRecordDetail from '../views/ParkManagement/InventoryManagement/OutboundRecordDetail.vue'
// import AgriculturalProductStatistics from '../views/ParkManagement/InventoryManagement/AgriculturalProductStatistics.vue'

// 园区管理--劳动人员管理 
import LaborIndex from '../views/ParkManagement/LaborManagement/LaborIndex.vue'
import AttendanceStatisticsManagement from '../views/ParkManagement/LaborManagement/AttendanceStatisticsManagement.vue'
// import StatisticalAnalysisOfLaborForce from '../views/ParkManagement/LaborManagement/StatisticalAnalysisOfLaborForce.vue'

// 智能控制
// import AgriculturalOptimization from '../views/AgriculturalOptimization/AgriculturalOptimization.vue'

//运维系统
import UserManagement from '../views/SystemManagement/UserManagement.vue'
import EquipmentManagement from '../views/SystemManagement/EquipmentManagement.vue'
// import EquipmentDetail from '../views/SystemManagement/EquipmentDetail.vue'

import Factory3D from "../views/Factory3D.vue"



Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
	children: [
	  { path: '', component: DataMonitoring },
	  // 园区数据监控中心
	  { path: '/dataMonitoring', name: 'dataMonitoring', component: DataMonitoring },
	  { path: '/agriculturalLandDataMonitoring', name: 'agriculturalLandDataMonitoring', component :() => import('../views/ParkDataMonitoringCenter/AgriculturalLandDataMonitoring.vue')},
	  // 园区数据分析中心
	  { path: '/comprehensiveDataAnalysis', name: 'comprehensiveDataAnalysis', component :() => import('../views/DataAnalysisCenter/ComprehensiveDataAnalysis.vue')},
	  { path: '/agriculturalDataAnalysis', name: 'agriculturalDataAnalysis', component :() => import('../views/DataAnalysisCenter/AgriculturalDataAnalysis.vue')},
	  { path: '/energyDataAnalysis', name: 'energyDataAnalysis',component :() => import('../views/DataAnalysisCenter/EnergyDataAnalysis.vue') },
	  // 园区管理--农产品管理
	  { path: '/inventoryManagementIndex', name: 'inventoryManagementIndex', component: InventoryManagementIndex },
	  { path: '/productStorage', name: 'productStorage', component: ProductStorage },
	  { path: '/exWarehouse', name: 'exWarehouse', component: ExWarehouse },
	  { path: '/productNameManagement', name: 'productNameManagement', component: ProductNameManagement },
	  { path: '/warehouseManagement', name: 'warehouseManagement', component: WarehouseManagement },
	  { path: '/inventoryQuery', name: 'inventoryQuery', component: InventoryQuery },
	  { path: '/warehousingRecord', name: 'warehousingRecord', component: WarehousingRecord },
	  { path: '/outboundRecord', name: 'outboundRecord', component: OutboundRecord },
	  { path: '/outboundRecordDetail', name: 'outboundRecordDetail', component: OutboundRecordDetail },
	  { path: '/agriculturalProductStatistics', name: 'agriculturalProductStatistics', component :() => import('../views/ParkManagement/InventoryManagement/AgriculturalProductStatistics.vue') },
	  
	  // 园区管理--劳动人员管理
	  { path: '/laborIndex', name: 'laborIndex', component: LaborIndex },
	  { path: '/attendanceStatisticsManagement', name: 'attendanceStatisticsManagement', component: AttendanceStatisticsManagement },
	  { path: '/statisticalAnalysisOfLaborForce', name: 'statisticalAnalysisOfLaborForce', component :() => import('../views/ParkManagement/LaborManagement/StatisticalAnalysisOfLaborForce.vue') },
	  
	  // 智能控制
	  { path: '/agriculturalOptimization', name: 'agriculturalOptimization', component :() => import('../views/AgriculturalOptimization/AgriculturalOptimization.vue')  },
	  
	  // 运维系统--用户管理
	  { path: '/userManagement', name: 'userManagement', component: UserManagement },
	  { path: '/equipmentManagement', name: 'equipmentManagement', component: EquipmentManagement },
	  { path: '/equipmentDetail', name: 'equipmentDetail', component :() => import('../views/SystemManagement/EquipmentDetail.vue')},
	  

	  { path: '/factory3D', name: 'factory3D', component: Factory3D }
	]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
