<template>
	<div class='inventory_index'>
		<div class="box">
			<div class="inventory_item">
				<div class="item bg1" @click="go(1)"><span>农产品入库</span></div>
				<div class="item bg2" @click="go(2)"><span>农产品出库</span></div>
				<div class="item bg3" @click="go(3)"><span>品名管理</span></div>
				<div class="item bg4" @click="go(4)"><span>仓库管理</span></div>
			</div>
			<div class="inventory_item">
				<div class="item bg5" @click="go(5)"><span>库存查询</span></div>
				<div class="item bg6" @click="go(6)"><span>入库记录</span></div>
				<div class="item bg7" @click="go(7)"><span>出库记录</span></div>
				<div class="item bg8" @click="go(8)"><span>农产品统计</span></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'InventoryIndex',
  data(){
	  return{
		 
	  }
  },
  methods:{
	  // 跳转到对应的功能页面
	  go(type){
		  switch (type){
		       case 1:
		           this.$router.push('/productStorage')
		           break;
		       case 2:
		           this.$router.push('/exWarehouse')
		           break;
		       case 3:
		           this.$router.push('/productNameManagement')
		           break;
			   case 4:
			       this.$router.push('/warehouseManagement')
			       break;
			   case 5:
			       this.$router.push('/inventoryQuery')
			       break;
			   case 6:
			       this.$router.push('/warehousingRecord')
			       break;
			    case 7:
			        this.$router.push('/outboundRecord')
			        break;
			    case 8:
			        this.$router.push('/agriculturalProductStatistics')
			        break;
		       default:
		           break;		    
		  }
	  },
  }
}
</script>

<style scoped="scoped">
.inventory_index{
	width: 100%;
	height: 100%;
	position: relative;
	padding: 5%;
	box-sizing: border-box;
}
.box{
	width: 100%;
	height: 100%;
	/* background-color: red; */
}
.inventory_item{
	width: 100%;
	height:45% ;
	margin-bottom: 5%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.inventory_item:last-child{
	margin-bottom: 0;
}
.item{
	width: 22%;
	height: 100%;
	line-height: 100%;
	font-size: 28px;
	font-family: PingFang SC;
	/* font-weight: bold; */
	color: #FFFFFF;
	position: relative;
	cursor: pointer;
}
.item:hover{
	opacity: 0.7;
}
.item span{
	position: absolute;
	bottom: 49px;
	left: 50%;
	transform: translateX(-50%);
}
.bg1{
	background: url(../../../assets/kucun/bg2.png) no-repeat center;
	background-size: contain;
}
.bg2{
	background: url(../../../assets/kucun/bg1.png) no-repeat center;
	background-size: contain;
}
.bg3{
	background: url(../../../assets/kucun/bg3.png) no-repeat center;
	background-size: contain;
}
.bg4{
	background: url(../../../assets/kucun/bg4.png) no-repeat center;
	background-size: contain;
}
.bg5{
	background: url(../../../assets/kucun/bg5.png) no-repeat center;
	background-size: contain;
}
.bg6{
	background: url(../../../assets/kucun/bg6.png) no-repeat center;
	background-size: contain;
}
.bg7{
	background: url(../../../assets/kucun/bg7.png) no-repeat center;
	background-size: contain;
}
.bg8{
	background: url(../../../assets/kucun/bg8.png) no-repeat center;
	background-size: contain;
}
/* .bg1:hover{
	background: url(../../../assets/kucun/bg1_s.png) no-repeat center;
	background-size: contain;
}
.bg2:hover{
	background: url(../../../assets/kucun/bg2_s.png) no-repeat center;
	background-size: contain;
}
.bg3:hover{
	background: url(../../../assets/kucun/bg3_s.png) no-repeat center;
	background-size: contain;
}
.bg4:hover{
	background: url(../../../assets/kucun/bg4_s.png) no-repeat center;
	background-size: contain;
}
.bg5:hover{
	background: url(../../../assets/kucun/bg5_s.png) no-repeat center;
	background-size: contain;
}
.bg6:hover{
	background: url(../../../assets/kucun/bg6_s.png) no-repeat center;
	background-size: contain;
}
.bg7:hover{
	background: url(../../../assets/kucun/bg7_s.png) no-repeat center;
	background-size: contain;
}
.bg8:hover{
	background: url(../../../assets/kucun/bg8_s.png) no-repeat center;
	background-size: contain;
} */
</style>
