<template>
	<div class='inventory_index'>
		<div class="item bg1" @click="go(1)"><span>出勤统计管理</span></div>
		<div class="item bg2" @click="go(2)"><span>劳动力统计分析</span></div>
	</div>
</template>

<script>
export default {
  name: 'InventoryIndex',
  data(){
	  return{
	  }
  },
  methods:{
	  // 跳转到对应的功能页面
	  go(type){
		  switch (type){
		       case 1:
		           this.$router.push('/attendanceStatisticsManagement')
		           break;
		       case 2:
		           this.$router.push('/statisticalAnalysisOfLaborForce')
		           break;
		       default:
		           break;		    
		  }
	  },
	  
  }
}
</script>

<style scoped="scoped">
.inventory_index{
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.item{
	width: 295px;
	height: 315px;
	font-size: 24px;
	font-family: PingFang SC;
	color: #FFFFFF;
	position: relative;
	cursor: pointer;
}
.item:hover{
	opacity: 0.7;
}
.item span{
	display: inline-block;
	width: 295px;
	position: absolute;
	bottom: 49px;
	left: 50%;
	transform: translateX(-50%);
}
.bg1{
	background: url(../../../assets/laodongli/bg1.png) no-repeat center;
	background-size: contain;
}
.bg2{
	background: url(../../../assets/laodongli/bg2.png) no-repeat center;
	background-size: contain;
}
/* .bg1:hover{
	background: url(../../../assets/laodongli/bg1_s.png) no-repeat center;
	background-size: contain;
}
.bg2:hover{
	background: url(../../../assets/laodongli/bg2_s.png) no-repeat center;
	background-size: contain;
} */
</style>

