<template>
  <div id="app">
   <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<script>
// import Mytitle from '@/components/mytitle/Mytitle'
import { sendWebsocket, closeWebsocket } from '@/api/websocket'
var fs = require("fs");
export default {
  name: 'app',
  data: function () {
    return {
  
    }
  },
  created () {
    // 接通websocket
    // sendWebsocket('ws://39.98.229.53:3001', this.wsChange, this.wsError) // 客户的服务器地址2
	// sendWebsocket('ws://169.254.77.53:3001', this.wsChange, this.wsError) // 客户的服务器地址2
  },
  destroyed () {
     closeWebsocket() // 离开之后断开websocket连接
  },
  methods: {
    wsError () {
      // 比如取消页面的loading
      console.log('No!')
    },
    // ws连接成功，后台返回的ws数据，组件要拿数据渲染页面等操作
    wsChange (res) {
      console.log(res)
    }
  }
}
</script>

<style lang="scss">
	*{
	  margin: 0;
	  padding: 0;
	}
	html,body,#app {
	  width: 100%;
	  height: 100%;
	  color: #606266;
	}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
// 自定义系统滚动条的样式
::-webkit-scrollbar  
{  
    width: 5px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(255,255,255,0.1); 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: rgba(4,33,94,1);
} 

</style>
