<template>
<div class="add_operate">
  <div class="el-icon-close close" @click="close"></div>
  <div class="marks_top">
  	<div class="marks_name">{{dialog.title}}</div>
  </div>
  <div class="content">
	  <el-form
	    ref="form"
	    :model="form"
	    :rules="rules"
	  >
	   
	    <el-form-item prop="uname" label="账号">
	      <el-input type="text" v-model="form.uname" placeholder="请输入"></el-input>
	    </el-form-item>
	    
	    <el-form-item prop="password" label="密码">
	      <el-input type="text" v-model="form.password" placeholder="请输入"></el-input>
	    </el-form-item>
	    
	    <el-form-item prop="name" label="姓名">
	      <el-input type="text" v-model="form.name" placeholder="请输入"></el-input>
	    </el-form-item>
		
		<el-form-item prop="power" label="权限">
		  <el-select v-model="form.power" placeholder="请选择" @change="typeChange">
		      <el-option
		        v-for="item in options"
		        :key="item.value"
		        :label="item.label"
		        :value="item.value">
		      </el-option>
		    </el-select>
		</el-form-item>
	    
	  </el-form>
  </div>
  <div class="btn">
	  <div class="submit" @click="onSubmit('form')">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;">确认</span>
	  </div>
	  <div class="cancel" @click="close">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;" >取消</span>
	  </div>
  </div>
</div>
</template>

<script>
import { doUser } from '@/api/index'
import axios from 'axios'
export default {
  name: 'ProductNameOperate',
  props: {
    dialog: Object,
    form: Object,
  },
  data () {
    return {
		rules: {
		          uname: [
		            { required: true, message: '请输入账号', trigger: 'blur' }
		          ],
				  password:[
				    { required: true, message: '请输入密码', trigger: 'blur' }
				  ],
				  name:[
				    { required: true, message: '请输入姓名', trigger: 'blur' }
				  ],
				  power:[
				    { required: true, message: '请选择权限', trigger: 'change' }
				  ],			
						  
		        },
		options: [{
		          value: 1,
		          label: '管理员'
		        }, {
		          value: 2,
		          label: '用户'
		        }],
		old_uname:this.form.uname,
		old_password:this.form.password,
		old_name:this.form.name,
		old_power:this.form.power,
    }
  },
  mounted () {

  },
  methods: {
	// 关闭
	close(){
		this.form.uname=this.old_uname
		this.form.password=this.old_password
		this.form.name=this.old_name
		this.form.power=this.old_power
		this.dialog.show = false
	},
	// 身份选择
	typeChange(value){
		console.log(value)
		this.form.power=value
	},
   //  // 提交按钮
    onSubmit (formName) {
	  console.log(this.form)
	  console.log(this.value)
	  this.$refs[formName].validate((valid) => {
	            if (valid) {
	              // 表单数据验证完成之后，提交数据;
	              const data = {
	                act:this.dialog.option,
	                id:this.form.id,
	                uname:this.form.uname,
	                password:this.form.password,
	                name:this.form.name,
	                power:this.form.power,
	              }
	              console.log(data)
	              doUser(data).then((res) => {  
	                console.log(res)
	                // 操作成功
	                if (res.msg === '成功') {
	                  this.$message({
	                    message: '操作成功！',
	                    type: 'success'
	                  })
	                  this.dialog.show = false
	                  this.$emit('update')
	                }
	              })
	                .catch((err) => {
	                  console.log(err)
	                })
	            } else {
	              console.log('error submit!!');
	              return false;
	            }
	   });
	 
    },
	
	
  }
}
</script>

<style scoped>
.add_operate{
  width: 400px;
  height: 450px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: rgba(10, 23, 56, 0.7);
  border: 2px solid #36CFC9;
  text-align: center;
}
 .close{
	 position: absolute;
	 right: 15px;
	 top: 15px;
	 color: #FFFFFF;
	 font-size: 20px;
 }
 .marks_top{
 	width: 100%;
 	height: 77px;
 	border-left: 5px solid #36CFC9;
 	padding:15px 0  0 25px;
 	box-sizing: border-box;
 	/* margin-bottom: 30px; */
 }
 .marks_name{
 	width: 50%;
 	height:48px ;
 	line-height: 48px;
 	background-image: linear-gradient(to right, rgba(54,207,201,0.5) , rgba(255,255,255,0));
 	color: #FFFFFF;
 	font-size: 16px;
 	font-family: PingFang SC;
 	font-weight: bold;
 	text-align: left;
 	padding-left:20px;
 	box-sizing: border-box;
 	letter-spacing: 2px;
 }
 .content{
	 width: 100%;
	 height: 260px;
	 /* background-color: red; */
	 margin-bottom: 30px;
	 padding-left: 30px;
	 box-sizing: border-box;
	 /* font-size: 16px;
	 font-family: PingFang SC;
	 font-weight: bold; */
 }
 .btn{
	 display: flex;
	 align-items: center;
	 justify-content: center;
 }
 .submit{
 	  width: 108px;
 	  height: 48px;
 	  background: url(../../assets/back_bg.png) no-repeat center;
 	  background-size: cover;
 	  border: 1px solid #36CFC9;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
	  margin-right: 30px;
 }
 .cancel{
 	  width: 108px;
 	  height: 48px;
 	  background: url(../../assets/back_bg.png) no-repeat center;
 	  background-size: cover;
 	  border: 1px solid #4B5052;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
 }
 /deep/.el-form{
 	/* width: 200px!important; */
 	position: relative;
 	left: 50%;
 	transform: translateX(-50%);
 } 
 /deep/.el-form-item__label{
 	 height: 48px;
	 line-height: 48px;
 	 width: 60px!important;
 	 color: #FFFFFF;
 	 text-align: justify;
  }
  /deep/.el-form-item label:after {
          content: "";
          display: inline-block;
          width: 100%;
      }    
 /deep/.el-form-item.is-required .el-form-item__label:before {
          content: none !important;
      }
/deep/.el-input{
	/* width: 301px!important; */
	width: 200px!important;
	height: 40px;
}
/deep/.el-input__inner{
	height: 48px!important;
	line-height: 48px;
}
/* /deep/.el-input__suffix{
	right: 30px;
} */
/deep/.el-form-item__content{
	width: 80%;
}
/deep/.el-select{
	margin-left: 0!important;
	margin-right: 0!important;
}
/deep/.el-select>.el-input{
	height: 48px;
	line-height: 48px;
	/* width: 100%!important; */
}
/deep/.el-select .el-input__inner{
	width: 200px!important;
}
</style>
