<template>
<div class="warehousing_record_detail">
  <div class="el-icon-close close" @click="close"></div>
<!--  <div class="marks_top">
  	<div class="marks_name">{{dialog.title}}</div>
  </div> -->
  <div class="content">
	  <div class="title">
	  	<div class="t_l"></div>
	  	<div class="t_r">
	  		<div>入库信息</div>
	  		<div class="line">
	  			<div class="line1">
	  				<div class="line3"></div>
	  			</div>
	  			<div class="line2">
	  				<div class="line4"></div>
	  			</div>
	  		</div>
	  	</div>
	  </div>
	  <el-table
	    :data="tableData"
	     size="mini"
	     fit
	  	  stripe
	    :default-sort="{prop: 'date', order: 'descending'}"
	    :row-style="{height: '40px'}"
	    :cell-style="{padding: '0'}"
	  	height="400"
	    style="width: 100%"
	   >
	  	<el-table-column type="index" label="序号" width="100"></el-table-column>
	  	 <el-table-column prop="vegetables" label="品名" align="center" ></el-table-column>
	  	 <el-table-column prop="batch_number" label="批号" align="center" ></el-table-column>
	  	 <el-table-column prop="unit" label="单位" align="center" ></el-table-column>
	  	 <el-table-column prop="num" label="总数量" align="center"></el-table-column>
	  	 <el-table-column prop="spec" label="规格" align="center" ></el-table-column>
	  	 <el-table-column prop="create_time" label="采集日期" align="center" style="height: 45px">
	  	 		<template slot-scope="scope">
	  	 			<div>{{timeFormate(parseInt(scope.row.create_time))}}</div>
	  	 		</template>
	  	 </el-table-column>
	  	 <el-table-column prop="warehouse" label="位置" align="center" ></el-table-column>
	     <!-- <el-table-column prop="operation" align="center" label="操作" >
	       <template slot-scope="scope">
	        <el-button
	           type="text"
	           size="small"
	  				class="out"
	  			style="color:#606266;"
	           @click="check(scope.row)"
	         >查看详情</el-button>
	  			 <el-button
	  			    type="text"
	  			    size="small"
	  			  	class="del"
	  			  	style="color:#606266;"
	  			    @click="del(scope.row)"
	  			  >删除</el-button>
	       </template>
	     </el-table-column> -->
	   </el-table>
	   <!-- 分页 -->
	   <el-row class="page">
	       <el-col>
	         <div class="pagination">
	           <el-pagination
	             v-if="paginations.total > 0"
	             @current-change="handleCurrentChange"
	             :current-page.sync="paginations.page_index"
	             :page-size="paginations.page_size"
	             layout="prev, pager, next, jumper"
	             :total="paginations.total">
	           </el-pagination>
	         </div>
	       </el-col>
	     </el-row>
  </div>
  
</div>
</template>

<script>
import { getStockLogInfo } from '@/api/index'
import axios from 'axios'
export default {
  name: 'WarehousingRecordDetail',
  props: {
    dialog: Object,
    form: Object,
  },
  data () {
    return {
		tableData: [],
		// 需要给分页组件传的信息
		paginations: {
		  page_index: 1, // 当前位于哪页
		  total: 1, // 总数
		  page_size: 10 // 1页显示多少条
		},
    }
  },
  mounted () {
     this.getProfile(1)
  },
  methods: {
	getProfile (pages) {
		console.log("切换至")
		console.log(pages,this.pagesNumber)
	  // 获取表格数据
	  const data = {
	    id:this.form.id,
		page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
	  // 请求数据
	  getStockLogInfo(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.paginations.total=res.count
		  this.tableData=res.data
		  console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 关闭
	close(){
		this.dialog.show = false
	},  
   //删除
   del(row){
      console.log(row)
      // this.dialog = {
      //   show: true,
      //   title: '删除提示',
      //   option: 'del'
      // }
      // this.form=row
      // console.log(this.form)
   },
   handleCurrentChange (page) {
       const number = String(page)
   	  this.pagesNumber=number
   	  console.log("切换至"+this.pagesNumber+"页")
       // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
   	  const data = {
		id:this.form.id,
   	    page: number,
   	    limit: '10',
   	  }
       console.log(data)
       // 发送请求
       // 请求数据
       getWordList(data)
         .then((res) => {
           console.log(res)
       	  console.log(res.data)
   		  this.tableData=res.data
         })
         .catch((err) => {
           console.log(err)
         })
   
       // // 当前页
       // const sortnum = this.paginations.page_size * (page - 1)
       // const table = this.allTableData.filter((item, index) => {
       //   return index >= sortnum
       // })
       // // 设置默认分页数据
       // this.tableData = table.filter((item, index) => {
       //   return index < this.paginations.page_size
       // })
     },
   	// 格式化后台传来的时间戳（年月日时分秒）
   	timeFormate (timeStamp) {
   	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
   	      const Y = date.getFullYear() + '-'
   	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
   	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
   	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
   	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
   		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
   	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
   	      return Y + M + D 
   	},
  
	
  }
}
</script>

<style scoped>
.warehousing_record_detail{
  width:80%;
  height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: rgba(10, 23, 56, 1);
  border: 2px solid #36CFC9;
  text-align: center;
  padding:10px 20px 20px 20px;
  box-sizing: border-box;
}
 .close{
	 position: absolute;
	 right: 15px;
	 top: 15px;
	 color: #FFFFFF;
	 font-size: 20px;
 }
 .content{
	 width: 100%;
	 height: 100%;
	 /* background-color: red; */
	
 }
 .title{
     width: 100%;
     height: 42px;
     display: flex;
     align-items: flex-end;
     aljustify-content: start;
     text-align: left;
 	  margin-top: 0;
 	  margin-bottom: 2%;
   }
   .t_l{
   	  width: 33px;
   	  height: 34px;
   	  background: url(../../assets/jiankong/quan_o.png);
   	  background-size: contain;
   	  margin-right: 14px;
   }
   .t_r{
   	  width: calc(100% - 47px);
   	  font-size: 16px;
   	  font-family: PingFang SC;
   	  font-weight: bold;
   	  color: #BFF2F0;
   	  height: 42px;
   	  line-height: 42px;
   }
   .line{
   	  width: 100%;
   	  height: 1px;
   	  display: flex;
 	  justify-content: space-between;
 	  align-items: center;
   }
   .line1{
 	  width: 84%;
 	  height: 1px;
 	  background: #535A6B;
   }
   .line2{
 	  width: 15%;
 	  height: 1px;
 	  background: #535A6B;
 	  text-align: center;
   }
   .line3{
 	width: 90%;
 	height: 1px; 
 	  background: #36CFC9;
   }
   .line4{
 	  position: relative;
 	  left: 50%;
 	  transform: translateX(-50%);
 	  width: 15px;
 	  height: 1px;
 	  background: #36CFC9;
   }
/deep/.el-table{
	  background-color: transparent!important;
  }
  .page{
	  margin-top: 30px;
  }
  .del{
  	width: 60px;
  	height: 24px!important;
  	line-height: 24px!important;
  	background:  rgba(215,140,71, 0.2);
  	border: 1px solid #D78C47;
  	color: #D78C47!important;
  	padding: 0;
  }
</style>

