<template>
  <div class="login">
	<div class="form_container">
	  <div class="manage_tip">
	    <span class="title">生态园区物联网管控平台</span>
	  </div>
	  <el-form :model="loginUser" status-icon :rules="rules" ref="loginForm" label-width="0px" class="loginForm">
		<div class="title_2">登 录</div>
	    <el-form-item label="" prop="person_name">
	      <el-input type="text" v-model="loginUser.person_name" placeholder="请输入用户名" prefix-icon="el-icon-user"></el-input>
	    </el-form-item>
	    <el-form-item label="" prop="person_pass">
	      <el-input type="password" v-model="loginUser.person_pass" placeholder="请输入密码" prefix-icon="el-icon-lock"></el-input>
	    </el-form-item>
		<el-form-item>
		   <el-checkbox v-model="checked" class="checkbox">记住密码</el-checkbox>
		</el-form-item>
	    <el-form-item>
		  <div class="submit_btn" @click="submitForm('loginForm')">登 录</div>
	    </el-form-item>
	  </el-form>
	</div>
	<div class="logo">国网中兴绿源公司</div>
	<div class="logo2">中国农业大学智慧农村能源创新团队</div>
  </div>
</template>

<script>

import { getLogin } from '../api/index'
export default {
  name: 'Login',
  data () {
    return {
      loginUser: {
        person_name: '',
        person_pass: ''
      },
      rules: {
        person_pass: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min:1, max: 30, message: '长度在 6 到 30 个字符', trigger: 'blur' }
        ]
      },
	  checked: false
    }
  },
  //页面加载调用获取cookie值
  mounted(){
      this.getCookie()
  },
  methods: {
   submitForm (formName) {
     console.log(this.loginUser)
     this.$refs[formName].validate(valid => {
       if (valid) {
         getLogin(this.loginUser).then((res) => {
           // 登录成功
           console.log(res)
           // 页面跳转
           if (res.msg === '成功') {
			   this.setCookie1(res.data.id,res.data.name,365);//存储用户id和name
			   //判断复选框是否被勾选 勾选则调用配置cookie方法
			      if(this.checked=true){
			        //传入账号名，密码，和保存天数3个参数
			       this.setCookie(this.loginUser.person_name,this.loginUser.person_pass,365);
			      }
			    this.$router.push('/index')
             // 存储数据
             this.$store.dispatch('setUser', res.data)
             // console.log(this.$store.state.user)
           } else {
             // 登录失败提示
             this.$message({
               showClose: true,
               message: '用户名或者密码不匹配，请重新输入',
               type: 'error'
             })
           }
         })
           .catch(function (err) {
             // eslint-disable-next-line no-console
             console.log(err)
           })
       } else {
         console.log('error submit!!')
         return false
       }
     })
   },
   //设置cookie
    setCookie(c_name,c_pwd,exdays) {
     var exdate=new Date();//获取时间
     exdate.setTime(exdate.getTime() + 24*60*60*1000*exdays);//保存的天数
     //字符串拼接cookie
     window.document.cookie="styUserName"+ "=" +c_name+";path=/;expires="+exdate.toGMTString();
     window.document.cookie="styUserPwd"+"="+c_pwd+";path=/;expires="+exdate.toGMTString();
	 window.document.cookie="styUserPwd"+"="+c_pwd+";path=/;expires="+exdate.toGMTString();
	 // console.log("cookie保存成功")
    },
	//设置id、name的cookie
	 setCookie1(c_id,c_name_z,exdays) {
	  var exdate=new Date();//获取时间
	  exdate.setTime(exdate.getTime() + 24*60*60*1000*exdays);//保存的天数
	  //字符串拼接cookie
	  window.document.cookie="styUserId"+"="+c_id+";path=/;expires="+exdate.toGMTString();
	  window.document.cookie="styUserName_z"+"="+c_name_z+";path=/;expires="+exdate.toGMTString();
	 },
    //读取cookie
    getCookie:function () {
     if (document.cookie.length>0) {
	  this.checked=true //证明内存中有保存密码
      var arr=document.cookie.split('; ');//这里显示的格式需要切割一下自己可输出看下
      for(var i=0;i<arr.length;i++){
       var arr2=arr[i].split('=');//再次切割
       //判断查找相对应的值
       if(arr2[0]=='styUserName'){
        this.loginUser.person_name=arr2[1];//保存到保存数据的地方
       }else if(arr2[0]=='styUserPwd'){
        this.loginUser.person_pass=arr2[1];
       }
      }
     }
    },
    //清除cookie
    clearCookie:function () {
     this.setCookie("","",-1);//修改2值都为空，天数为负1天就好了
    }
    
  }
}
</script>

<style scoped>
  .login {
    width: 100%;
    height: 100vh;
	background: url(../assets/login/bg.png) no-repeat;
	background-size: cover;
    position: relative;
  }
  .form_container {
    width: 768px;
    height: 554px;
    position: absolute;
    top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
    padding: 40px 49px 56px 52px;
	box-sizing: border-box;
    text-align: center;
	background: rgba(0,0,0,0.4);
  }
  .form_container .manage_tip .title {
	display: inline-block;
    width: 100%;
	height: 32px;
	line-height: 32px;
	color: #36CFC9;
	font-size: 32px;
	font-weight: bold;
	font-family: ZhenyanGB;
	letter-spacing: 1px;
	
  }
  .loginForm {
	width: 584px;
	height: 386px;
	margin-left: 40px;
    margin-top: 41px;
    background-color: #0A1B43;
    padding: 45px 92px 60px 92px;
	box-sizing: border-box;
  }
  .title_2{
	  height: 23px;
	  font-size: 24px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #FFFFFF;
	  margin-bottom: 48px;
  }
  .checkbox{
	  width: 100%;
	  text-align: left;
	  color: #FFFFFF;
	  font-size: 12px;
  }

  .submit_btn {
    width: 400px;
    height: 42px;
    background: #36CFC9;
    border-radius: 4px;
	font-size: 18px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	cursor: pointer;
  }
  .tiparea {
    text-align: right;
    font-size: 12px;
    color: #333;
  }
  .tiparea p a {
    color: #409eff;
  }
  /deep/ .el-input__inner{
	  background: #0A1738;
	  border: 1px solid #2F334E;
	  color: #FFFFFF;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
	  background-color: #36CFC9;
	  border-color: #36CFC9;
  }
  /deep/ .el-checkbox__inner:hover{
	   border-color: #36CFC9;
  }
  
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
	  color: #FFFFFF;
  }
  .logo{
	  font-size: 24px;
	  font-family: PingFang SC;
	  letter-spacing: 2px;
	  color: #FFFFFF;
	  position: absolute;
	  bottom: 46px;
	  left: 50%;
	  transform: translateX(-50%);
  }
  .logo2{
	font-size: 16px;
	font-family: PingFang SC;
	letter-spacing: 2px;
	color: rgba(255,255,255,1);
	position: absolute;
	bottom: 30px;
	right:50px;
  }
</style>
