import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const types = {
  SET_USER: 'SET_USER', // 用户信息
}

const state = { // 需要维护的状态
  user: {}, // 存储用户信息

}

const getters = {
  user: state => state.user,
}

const mutations = {
  [types.SET_USER] (state, user) {
    if (user) { state.user = user } else { state.user = {} }
  }
}

const actions = {
  setUser: ({ commit }, user) => {
    commit(types.SET_USER, user)
  },
  clearCurrentState: ({ commit }) => {
    commit(types.SET_IS_AUTNENTIATED, false)
    commit(types.SET_USER, null)
  },

}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})

