<template>
<!-- 农产品出库 -->
 <div class="ex_warehouse">
   <!-- 头部 -->
   <div class="land_head">
   	<!-- 搜索 -->
   	<div class="select">
		<el-select v-model="value" placeholder="请选择品名" @change="nameChange">
		    <el-option
		      v-for="item in options"
		      :key="item.id"
		      :label="item.vegetables"
		      :value="item.id"
			   popper-class="select_tree"
			  >
		    </el-option>
		  </el-select>	
   	</div>
   	<div class="land_title">农产品出库</div>
   	<div class="back" @click="back">
   		<i class="el-icon-back"></i>
   		<span style="margin-left: 4px;">返回</span>
   	</div>
   </div>
   <div class="table_container">
	   <div class="title">
	   	<div class="t_l"></div>
	   	<div class="t_r">
	   		<div>库存信息</div>
	   		<div class="line">
	   			<div class="line1">
	   				<div class="line3"></div>
	   			</div>
	   			<div class="line2">
	   				<div class="line4"></div>
	   			</div>
	   		</div>
	   	</div>
	   </div>
	   <el-table
	     :data="tableData"
	      size="mini"
	      fit
	   	  stripe
	     :default-sort="{prop: 'date', order: 'descending'}"
	     :row-style="{height: '40px'}"
	     :cell-style="{padding: '0'}"
		 height="250"
	     style="width: 100%"
	    >
	   	 <el-table-column type="expand">
	   	       <template slot-scope="props">
	   	         <el-form label-position="left" inline class="demo-table-expand">
					<el-form-item label="" v-for="(item,index) in props.row.stocklist" :key='index' v-if='props.row.stocklist.length>0'>
						<div>
							<span>位置 :</span>
							<span>{{item.warehouse}}</span>
						</div>
						<div>
							<span>数量 :</span>
							<span>{{item.stock}}</span>
						</div>
						<div
						  class="out"
						  @click="addOut(props.row,item)"
						>出库</div>
					</el-form-item> 
					<el-form-item v-else>
						<div>暂无数据</div>
					</el-form-item>
	   	         </el-form>
	   	       </template>
	   	     </el-table-column>
	   	  <el-table-column type="index" label="序号" width="100"></el-table-column>
	   	  <el-table-column prop="vegetables" label="品名" align="center" ></el-table-column>
	   	  <el-table-column prop="unit" label="单位" align="center" ></el-table-column>
	   	  <el-table-column prop="count_stock" label="总数量" align="center"></el-table-column>
	   	  <el-table-column prop="spec" label="规格" align="center" ></el-table-column>
	    </el-table>
	    <!-- 分页 -->
	    <el-row class="page">
	        <el-col>
	          <div class="pagination">
	            <el-pagination
	              v-if="paginations.total > 0"
	              @current-change="handleCurrentChange"
	              :current-page.sync="paginations.page_index"
	              :page-size="paginations.page_size"
	              layout="prev, pager, next, jumper"
	              :total="paginations.total">
	            </el-pagination>
	          </div>
	        </el-col>
	      </el-row>
   
  </div>
   <div class="table_container table2">
   	   <div class="title">
   	   	<div class="t_l"></div>
   	   	<div class="t_r">
   	   		<div>出库单</div>
   	   		<div class="line">
   	   			<div class="line1">
   	   				<div class="line3"></div>
   	   			</div>
   	   			<div class="line2">
   	   				<div class="line4"></div>
   	   			</div>
   	   		</div>
   	   	</div>
   	   </div>
   	   <el-table
   	     :data="outList"
   	      size="mini"
   	      fit
   	   	  stripe
   		  height="250"
   	     :default-sort="{prop: 'date', order: 'descending'}"
   	     :row-style="{height: '40px'}"
   	     :cell-style="{padding: '0'}"
   	     style="width: 100%"
   		  @selection-change="handleSelectionChange"
   	    >
   		 <el-table-column type="selection" width="55"></el-table-column>
   	   	 <el-table-column type="index" label="序号"></el-table-column>
   	     <el-table-column prop="vegetables" label="品名" align="center" ></el-table-column>
		 <el-table-column prop="warehouse" label="位置" align="center" ></el-table-column>
   	     <el-table-column prop="unit" label="单位" align="center" ></el-table-column>
		 <el-table-column prop="spec" label="规格" align="center" ></el-table-column>
   	     <el-table-column prop="total" label="总数量" align="center"></el-table-column>
   	     <el-table-column prop="num1" label="西单" align="center"></el-table-column>
   	     <el-table-column prop="num2" label="银座" align="center"></el-table-column>
		 <el-table-column prop="num3" label="未来城" align="center"></el-table-column>
		 <el-table-column prop="num4" label="白广路" align="center"></el-table-column>
		 <el-table-column prop="num5" label="都城" align="center"></el-table-column>
		 <el-table-column prop="num6" label="电力医院" align="center"></el-table-column>
		 <el-table-column prop="num7" label="英大" align="center"></el-table-column>
		 <el-table-column prop="num8" label="物资" align="center"></el-table-column>
		 <el-table-column prop="num9" label="国际" align="center"></el-table-column>
		 <el-table-column prop="num10" label="中再" align="center"></el-table-column>
		 <el-table-column prop="num11" label="通航" align="center"></el-table-column>
		 <el-table-column prop="num12" label="国康" align="center"></el-table-column>
		 <el-table-column prop="num13" label="西局" align="center"></el-table-column>
   	      <el-table-column prop="operation" align="center" label="操作" >
   	        <template slot-scope="scope">
   	          <el-button
   	            type="text"
   	            size="small"
   				class="del"
   	   			style="color:#606266;"
   	            @click="onDel(scope.row,scope.$index)"
   	          >删除</el-button>
   	        </template>
   	      </el-table-column>
   	    </el-table>
   </div>
   <div type="primary" class="submit" @click='submit'>
   	<img src="../../../assets/del.png" />
   	<span>提交出库单</span>
   </div>
  <!-- 出库数量填写页面 -->
  <div class="marks" v-if="dialog.show">
  	<OutOperate :dialog="dialog" :form="form" @update="getOutListItem"></OutOperate>
  </div>

</div>
</template>

<script>
import { getStockList,getVegetablesList,out_stock} from '@/api/index'
import DeleteRemain from '../../../components/LaborManagement/DeleteRemain.vue'
import OutOperate from '../../../components/InventoryManagement/OutOperate.vue'
export default {
  name: 'ExWarehouse',
  data () {
    return {
		isShow:false,
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [
		  // {name:'西红柿',dw:'斤',sl:'50',gg:'50斤/袋',num:3,child:[{id:1,name:'仓库1',num:1},{id:2,name:'仓库2',num:1},{id:3,name:'仓库3',num:1}]},
		  // {name:'辣椒',dw:'斤',sl:'50',gg:'50斤/袋',num:30,child:[{id:1,name:'仓库1',num:10},{id:2,name:'仓库2',num:10},{id:3,name:'仓库3',num:10}]},
		  // {name:'白菜',dw:'斤',sl:'50',gg:'50斤/袋',num:10,child:[{id:1,name:'仓库1',num:3},{id:2,name:'仓库2',num:4},{id:3,name:'仓库3',num:3}]},
		  // {name:'黄瓜',dw:'斤',sl:'50',gg:'50斤/袋',num:6,child:[{id:1,name:'仓库1',num:2},{id:2,name:'仓库2',num:2},{id:3,name:'仓库3',num:2}]},
		
	  ],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 10 // 1页显示多少条
      },
	  dialog:{
	    show: false,
	    title: '',
	    option: ''
	  },
	  form: {},
	  value:'',//默认选择的内容
	  input_txt:'',//查询内容
	  options: [],
	  value: '',
	  out_num:'',//出库数量
	  multipleSelection: []	,//表格中选中的内容
	  outList:[],//出库提交列表    
	  u_id:''//用户id
    }
  },
  components:{
  	  DeleteRemain,
	  OutOperate
  },
  created () {
    this.getVegetablesName()
    // 加载数据表
    this.getProfile(1)
	this.getCookie()
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	getVegetablesName() {
		console.log("获取品名列表")
	  // 获取表格数据
	  const data = {
	    type: '2',//1 分页 2不分页
		page: 1,
	    limit: '10',
	  }
	  console.log(data)
	  // 请求数据
	  getVegetablesList(data)
	    .then((res) => {
	          console.log(res)
	  		  console.log(res.data)
	  		  this.options=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getProfile (pages) {
		console.log("获取库存信息")
		console.log(pages,this.pagesNumber)
	  // 获取表格数据
	  const data = {
	    v_id:this.value,
		page: pages? pages:this.pagesNumber,
	    limit: '10',
	  }
	  // 请求数据
	  getStockList(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.paginations.total=res.count
		  this.tableData=res.data
		  console.log("总共有"+ this.paginations.total+"条数据")
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getOut(listData) {
		console.log("提交出库单")
	  // 获取表格数据
	  const data = {
	    list: JSON.stringify(listData),
		u_id:this.$store.getters.user.id?this.$store.getters.user.id:this.u_id
	  }
	  console.log(data)
	  // 请求数据
	  out_stock(data)
	    .then((res) => {
	      console.log(res)
	  	 // 操作成功
	  	 if (res.code === 200) {
	  	   this.$message({
	  	     message: '操作成功！',
	  	     type: 'success'
	  	   })
	  	   this.back()
	  	 }
	  	 
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	//读取cookie中的用户id
	getCookie () {
	 if (document.cookie.length>0) {
	  var arr=document.cookie.split('; ');//这里显示的格式需要切割一下自己可输出看下
	  for(var i=0;i<arr.length;i++){
	   var arr2=arr[i].split('=');//再次切割
	   //判断查找相对应的值
	   if(arr2[0]=='styUserId'){
	    this.u_id=arr2[1];//保存到保存数据的地方
	   }
	   console.log(this.u_id)
	  }
	 }
	},
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 品名选择框事件
	nameChange(value) {
	    console.log(value);
		// 更新表格
		this.getProfile (1)
	},
	// 出库列新增
	addOut(row,item){
		console.log(row)
		console.log(item)
		this.dialog = {
		  show: true,
		  title: '出库列新增',
		  option: 'add'
		}
		this.form={
			vegetables:row.vegetables,
			stock:item.stock,
			w_id:item.w_id,//仓库位置
			v_id:item.v_id,
			spec:row.spec,
			unit:row.unit,
			warehouse:item.warehouse
		}
	},
	// 出库单行删除
	onDel (row,index) {
	  console.log('删除')
	  console.log(row,index)
	  this.outList.splice(index,1)
	},
	// 得到新增的出库列
	getOutListItem(data){
		console.log(data)
		this.outList.push(data)
		
	},
	// 勾选框选中情况
	handleSelectionChange(val) {
		console.log('勾选框选中情况')
	    this.multipleSelection = val;
		console.log(this.multipleSelection)
	},
	// 确认出库
	submit(){
	   console.log(this.multipleSelection)
	   if(this.multipleSelection.length>0){
	   	console.log('提交')
	   	// let obj={}
	   	let listData=[]
	   	this.multipleSelection.forEach(function(item){
	   		console.log(item)
			let obj={}
	   		obj.w_id=item.w_id
	   		obj.v_id=item.v_id
	   		obj.num=item.total
	   		obj.xidan=item.num1
			obj.yinzuo=item.num2
			obj.weilaicheng=item.num3
			obj.baiguanglu=item.num4
			obj.ducheng=item.num5
			obj.dianliyiyuan=item.num6
			obj.yingda=item.num7
			obj.wuzi=item.num8
			obj.guoji=item.num9
			obj.zhongzai=item.num10
			obj.tonghang=item.num11
			obj.guokang=item.num12
			obj.xiju=item.num13
	   		listData.push(obj)
	   	})
	   	console.log(listData)
	   	this.getOut(listData)
	   }else{
	   	this.$message({
	   	   message: '请勾选需要提交的数据！',
	   	   type: 'error',
	   	   offset:400
	   	})
	   }
		
		
	},
    handleCurrentChange (page) {
      const number = String(page)
	  this.pagesNumber=number
	  console.log("切换至"+this.pagesNumber+"页")
      // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  const data = {
		v_id:this.value,
	    page: number,
	    limit: '10',
	  }
      console.log(data)
      // 发送请求
      // 请求数据
      getStockList(data)
        .then((res) => {
          console.log(res)
      	  console.log(res.data)
		  this.tableData=res.data
        })
        .catch((err) => {
          console.log(err)
        })

      // // 当前页
      // const sortnum = this.paginations.page_size * (page - 1)
      // const table = this.allTableData.filter((item, index) => {
      //   return index >= sortnum
      // })
      // // 设置默认分页数据
      // this.tableData = table.filter((item, index) => {
      //   return index < this.paginations.page_size
      // })
    },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
  // 监听屏幕变化
  // watch: {
  //   // 如果 `fullHeight ` 发生改变，这个函数就会运行
  //   fullHeight: function (val) {
  //     if (!this.timer) {
  //       this.fullHeight = val
  //       this.changePageSize(this.fullHeight)
  //       this.getProfile()
  //       console.log(val)
  //       console.log(this.paginations.page_size)
  //       this.timer = true
  //       const that = this
  //       setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
  //         that.timer = false
  //       }, 100)
  //     }
  //   }

  // }
}
</script>
<style scoped>
  .ex_warehouse{
	width: 100%;
	height: 100%;
	padding:0 30px 30px 30px;
	box-sizing: border-box;
	overflow: auto;
  }
  .land_head{
  	  width: 100%;
  	  height: 78px;
  	  margin-bottom: 30px;
  	  display: flex;
  	  align-items: flex-end;
  	  justify-content: start;
  	  position: relative;
  }
  .select{
  	  /* width: 600px; */
  	  height: 48px;
	  display: flex;
	  justify-content: start;
	  align-items: center;
  	  /* background-color: #1CCA81; */
  }
  .land_title{
  	  width: 554px;
  	  height: 78px;
  	  line-height: 78px;
  	  font-size: 28px;
  	  font-family: PingFang SC;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  margin-left: 100px;
  	  letter-spacing: 2px;
  	  background: url(../../../assets/title_bg.png) no-repeat center;
  	  background-size: contain;
	  position: absolute;
	  left: 50%;
	  transform: translateX(-50%);
  }
  .add{
  	width: 108px;
  	height: 48px;
  	background: #0B1326;
  	border: 1px solid #36CFC9;
  	font-size: 14px;
  	font-family: PingFang SC;
  	font-weight: bold;
  	color: #FFFFFF;
  	display: flex;
  	justify-content: center;
  	align-items: center;
  	cursor: pointer;
  	position: absolute;
  	right:165px;
  	bottom:0;
  }
  .back{
  	  position: absolute;
  	  right: 0;
  	  bottom:0;
  	  width: 135px;
  	  height: 48px;
  	  line-height: 48px;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  letter-spacing: 2px;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  background: url(../../../assets/back_bg.png) no-repeat center;
  	  background-size: contain;
  	  display: flex;
  	  align-items: center;
  	  justify-content: center;
	  cursor: pointer;
  }
  .back i{
  	  font-size: 18px;
  }
  .ex_warehouse .table_container{
	width: 100%;
	height: calc(60% - 100px);
	background: rgba(10,23,56,0.7);
    padding:0px 20px 20px 20px;
	box-sizing: border-box;
  }
  .table2{
	height:calc(35% - 60px)!important; 
	 margin-top: 30px;
	  margin-bottom: 20px;
  }
  .title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: flex-end;
      aljustify-content: start;
      text-align: left;
  	  margin-top: 0;
	  margin-bottom: 2%;
    }
    .t_l{
    	  width: 33px;
    	  height: 34px;
    	  background: url(../../../assets/jiankong/quan_o.png);
    	  background-size: contain;
    	  margin-right: 14px;
    }
    .t_r{
    	  width: calc(100% - 47px);
    	  font-size: 16px;
    	  font-family: PingFang SC;
    	  font-weight: bold;
    	  color: #BFF2F0;
    	  height: 42px;
    	  line-height: 42px;
    }
    .line{
    	  width: 100%;
    	  height: 1px;
    	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
    }
    .line1{
  	  width: 84%;
  	  height: 1px;
  	  background: #535A6B;
    }
    .line2{
  	  width: 15%;
  	  height: 1px;
  	  background: #535A6B;
  	  text-align: center;
    }
    .line3{
  	width: 90%;
  	height: 1px; 
  	  background: #36CFC9;
    }
    .line4{
  	  position: relative;
  	  left: 50%;
  	  transform: translateX(-50%);
  	  width: 15px;
  	  height: 1px;
  	  background: #36CFC9;
    }
	.out{
		width: 60px;
		height: 24px!important;
		line-height: 24px!important;
		background: rgba(54, 207, 201, 0.2);
		border: 1px solid #36CFC9;
		color: #36CFC9!important;
		padding: 0;
		text-align: center;
		cursor: pointer;
	}
	.del{
		width: 60px;
		height: 24px!important;
		line-height: 24px!important;
		background:  rgba(215,140,71, 0.2);
		border: 1px solid #D78C47;
		color: #D78C47!important;
		padding: 0;
	}
  .ex_warehouse .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /deep/.el-table{
	  height: calc(100% - 120px)!important;
	  margin-top: -10px;
  }
  /deep/ .table2 .el-table{
	  margin-top: -10px;
	  height: calc(100% - 60px)!important;
	 
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
 /* .marks{
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,0.6);
  } */
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
  /deep/.el-table{
	  background-color: #0B1326;
	  /* background-color: transparent!important; */
  }
  /deep/.el-table th, .el-table tr{
	  background-color: rgba(4,33,95,0.9)!important;
	  color: #FFFFFF;
	  padding: 0;
	  height: 40px!important;
  }
  /deep/.el-table--enable-row-transition .el-table__body td{
	 /* background: #0B1326; */
	 background-color: rgba(11,19,38,0.9);
	 color: #FFFFFF;
  }
  /deep/.el-table tbody tr:hover>td { 
  	 /* background:rgba(54,207,201,0.6); */
	background:rgba(12,15,16,0.8);
  	 color: #FFFFFF;
  }
  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
	  /* background:rgba(12,15,16,0.8); */
	  /* background:  rgba(4,33,95,0.9); */
	  background-color: rgba(11,19,38,0.9);
	  color: #FFFFFF;
  }
  /deep/.el-table__expand-icon{
	  height: 40px;
	  line-height: 40px;
	  color: #FFFFFF;
  }
  /deep/.el-form--inline .el-form-item__label{
	  color: #FFFFFF;
  }
  /deep/.el-form--inline .el-form-item{
	  width: 100%;
	  margin-bottom: 0;
  }
  /deep/.el-form--inline .el-form-item__content{
	display: flex!important;
	align-items: center;
	justify-content: start;  
	position: relative;
  }
  /deep/.el-form--inline .el-form-item__content div:first-child{
  	margin-right:50px ;  
  }
  /deep/.el-form--inline .el-form-item__content div:last-child{
  	position: absolute;
	 right: 0;
  }
  /deep/.el-form--inline .el-form-item__content div span{
	  margin-right: 10px;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  	  background-color: #36CFC9;
  	  border-color: #36CFC9;
  }
  /deep/ tr{
	  background-color: transparent!important;
  }
  /deep/.el-select{
	  margin-right: 30px;
  }
  /deep/.el-select .el-input__inner{
  	  height: 48px;
  	  width: 262px;
  	  background-color: rgba(11,19,38,0.5);
  	  border: 1px solid #36CFC9;
  	  color: #FFFFFF !important;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner{
	  border: 1px solid #36CFC9;
  }
  /deep/.el-input__inner{
	  height: 48px;
	  width: 262px;
	  background-color: rgba(11,19,38,0.5);
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
	  text-align: left!important;
  }
  /deep/.el-input__suffix{
	  color: #FFFFFF !important;
  }
  /deep/.el-pagination__jump{
  	color: #FFFFFF;
  }
  /deep/.el-pager li{
	  width: 40px;
	  height: 40px;
	  line-height: 40px;
	  background: #0C0F10;
	  border: 1px solid #4B5052;
	  color: #FFFFFF;
	  padding: 0;
	  margin: 0 10px;
  }
  /deep/.el-pagination .btn-next, .el-pagination .btn-prev{
	 width: 40px;
	 height: 40px;
	 line-height: 40px;
	 background: #0C0F10;
	 border: 1px solid #4B5052;
	 color: #FFFFFF;
	 padding: 0; 
  }
  /deep/.el-pagination button:disabled{
	width: 40px;
	height: 40px;
	line-height: 40px;
	background: #0C0F10;
	opacity: 0.5;
	border: 1px solid #4B5052;
	color: #FFFFFF;
	padding: 0;  
  }
  /deep/.el-pagination__jump{
	  height: 40px!important;
	  line-height: 40px!important;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
	  width: 80px;
	  border-color: #FFFFFF
  }
  /deep/.el-pager li.active{
	  background: #0B1326;
	  /* background: rgba(54, 207, 201, 0.2); */
	  border: 1px solid #36CFC9;
	  font-weight: bold;
  }
  /deep/.el-pagination .btn-prev{
	 width: 40px;
	 height: 40px;
	 line-height: 40px;
	 background: #0C0F10;
	 opacity: 0.5;
	 border: 1px solid #4B5052;
	 color: #FFFFFF;
	 padding: 0; 
  }
 .marks{
 	position: absolute;
 	top: 0;
 	left: 0;
 	width: 100%;
 	height: 100%;
 	background: rgba(0,0,0,0.8);  
 }
 .marks_box{
 	width: 436px;
 	height: 271px;
 	background: rgba(10, 23, 56, 0.7);
 	border: 2px solid #36CFC9;
 	position: absolute;
 	left: 50%;
 	top: 50%;
 	transform: translate(-50%,-50%);
 }
 .close{
	 position: absolute;
	 right: 15px;
	 top: 15px;
	 color: #FFFFFF;
	 font-size: 20px;
 }
 .marks_top{
 	width: 100%;
 	height: 77px;
 	border-left: 5px solid #36CFC9;
 	padding:15px 0  0 25px;
 	box-sizing: border-box;
 	margin-bottom: 30px;
 }
 .marks_name{
 	width: 50%;
 	height:48px ;
 	line-height: 48px;
 	background-image: linear-gradient(to right, rgba(54,207,201,0.5) , rgba(255,255,255,0));
 	color: #FFFFFF;
 	font-size: 16px;
 	font-family: PingFang SC;
 	font-weight: bold;
 	text-align: left;
 	padding-left:20px;
 	box-sizing: border-box;
 	letter-spacing: 2px;
 }
 .form{
	 width: 400px;
	 height: 48px;
	 display: flex;
	 align-items: center;
	 align-items: center;
	 position: relative;
	 left: 50%;
	 transform: translateX(-50%);
	 margin-bottom: 30px;
 }
 .label{
	 width: 100px;
	 height: 20px;
	 font-size: 16px;
	 font-family: PingFang SC;
	 color: #BFF2F0;
	 letter-spacing: 2px;
	 margin-right: 15px;
 }
 .submit{
 	 width: 158px;
 	 height: 40px;
	 line-height: 40px;
 	 /* background: rgba(10,23,56,0.7); */
	 background: url(../../../assets/back_bg.png) no-repeat center;
	 background-size: cover;
 	 border: 1px solid #36CFC9;
 	 color: #FFFFFF;
 	 font-size: 16px;
 	 font-family: PingFang SC;
 	 font-weight: bold;
 	 letter-spacing: 5px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  position: relative;
	  left: 50%;
	  transform: translateX(-50%);
 }
 .submit img{
	 width: 17px;
	 height: 17px;
 }
 .submit span{
	 display: inline-block;
	 height: 30px;
	 line-height: 30px;
	 margin-left: 10px;
 }
</style>
<style>
/* // 级联选择器样式 */
.el-select-dropdown {
    background-color: rgba(11,19,38);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
   /* font-weight: 400; */
    color: #fff!important;
    line-height: 20px;
    border: none;
    }
.el-select-dropdown__item{
	color: #fff!important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
	background-color: rgba(0,0,0,1);
}
.el-select-dropdown__item.selected{
	color: #36CFC9!important;
}
</style>


