<template>
	<div class="factory_3d">
		<!-- <iframe src="http://demos.dysense.cn/3d/kongtiao/" target="iframe_a" width="100%" height="100%"></iframe> -->
		<!-- <iframe src="http://demos.dysense.cn/3d/3DView" target="iframe_a" width="100%" height="100%"></iframe> -->
		
		<!-- <iframe src="http://gardens.dysense.cn/Greenhouse/" target="iframe_a" width="100%" height="100%" frameborder="0" scrolling="no" id="bdIframe" ></iframe> -->
		<iframe src="http://gardens.dysense.cn/Greenhouse/" target="iframe_a" width="100%" height="100%"  id="bdIframe" ></iframe>
	</div>
</template>

<script>
export default {
  name: 'Factory3D',
  data(){
	  return{}
	  },
  created() {
     
    },
    mounted(){
      /**
       * iframe-宽高自适应显示   
       */
      const oIframe = document.getElementById('bdIframe');
      const deviceWidth = document.documentElement.clientWidth;
      const deviceHeight = document.documentElement.clientHeight;
      oIframe.style.width = Number(deviceWidth) + 'px'; //数字是页面布局宽度差值
      oIframe.style.height = (Number(deviceHeight)-60) + 'px'; //数字是页面布局高度差
  },
  methods: {
  	
  },
}
</script>

<style scoped="scoped">
.factory_3d{
	width: 100%;
	height:100%;
	/* height: calc(100% - 60px) !important; */
	/* padding: 20px 36px; */
	box-sizing: border-box;
	background-color: #292C48;
	color: #FFFFFF;
	overflow: hidden;
}	
iframe{
	width: 100%;
	/* height: 912px; */
	/* height: calc(100% - 60px) !important; */
	border: none;
	/* background-color: #008AFD; */
	overflow: hidden;
}
</style>
