<template>
  <div class="index">
    <HeadNav></HeadNav>
    <!-- <LeftMenu></LeftMenu> -->
    <div class="rightContainer">
      <router-view></router-view>
    </div>
	<div class="logo2">中国农业大学智慧农村能源创新团队</div>
  </div>
</template>

<script>
import HeadNav from '../components/HeadNav'
import LeftMenu from '../components/LeftMenu'
export default {
  name: 'index',
  components: { HeadNav, LeftMenu }
}
</script>

<style scoped>
  .index {
    width: 100%;
    height: 100%;
    overflow: hidden;
	background: url(../assets/jiankong/bg.png) no-repeat center;
	background-size: cover;
	background-color:#051C4E;
  }
  .rightContainer {
    position: relative;
    top: 0;
    /* left: 140px; */
   /* width: calc(100% - 140px); */
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
  }
  .logo2{
  	font-size: 16px;
  	font-family: PingFang SC;
  	letter-spacing: 2px;
  	color: rgba(255,255,255,0.4);
  	position: absolute;
  	bottom: 10px;
  	right:50px;
  	/* transform: translateX(-50%);  */
  }
</style>
