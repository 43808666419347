<template>
<div class="home_right">
	<div class="l_top">
		<div class="title">
			<div class="t_l"></div>
			<div class="t_r">
				<div>能源数据监测</div>
				<div class="line"></div>
			</div>
		</div>
		<div class="ny">
			<div class="l_top_left">
				<div class="today">
					<span>今日新能源累计发电量</span>
					<span class="today_num">{{electricObj.count_hair_kwh}}Kwh</span>
				</div>
				<div class="table">
					<el-table
					  :data="tableData"
					   size="mini"
					   fit
					   height="100%"
					  :header-cell-style="{color:'#fff',background:'rgba(10,23,56,0.7)',height: '30px'}"
					  :default-sort="{prop: 'date', order: 'descending'}"
					  :row-style="{height: '30px',background:'#0B1326',color:'#fff','border-bottom':'5px solid #FFFFFF'}"
					  :cell-style="{padding: '0'}"
					  :row-class-name="TableRowClassName"
					  style="width: 100%"
					 >
					   <el-table-column prop="energy_name" label="园区新能源设备" align="left" ></el-table-column>
					   <el-table-column prop="is_line" label="运行中" align="left" class="blue">
						   <template slot-scope="scope">
						   	 <div class="blue">{{scope.row.is_line}}</div>
						   </template>
					   </el-table-column>
					   <el-table-column prop="is_fault" label="故障" align="left" >
						   <template slot-scope="scope">
						   	<div :class="scope.row.is_fault>0?'yellow':''">{{scope.row.is_fault}}</div>
						   </template>
					   </el-table-column>
					 </el-table>
				</div>
				
			</div>
			<div class="l_top_right">
				<div class="today">
					<span>今日农业设备累计用电量</span>
					<span class="today_num">{{electricObj.count_use_kwh}}Kwh</span>
				</div>
				<div class="chart">
					<div id="myChart_electricQuantity" ref='chart'></div>
				</div>
			</div>
			
		</div>
		
		 
	</div>
	<div class="l_bottom">
	  <!--日光棚  -->
	  <div class="box">
		   <el-carousel  direction="vertical" :autoplay="true" :interval='5000'>
		      <el-carousel-item v-for="(item,index) in sunList" :key='index' class="item" style="transform: scale(1);">
		        <div class="card"  v-for="(it,idx) in item" :key='idx'>
					<div class="title">
						<div class="t_l bg2"></div>
						<div class="t_r">
							<div>{{it.yard_name}}</div>
							<div class="line"></div>
						</div>
					</div>
					<div class="card_data">
						<div v-for="(item_label,index_label) in labelList1" :key='index_label' class="data_item">
						   <div>{{item_label}}<i></i></div>
						   <span class="data_number" v-if="item_label=='空气温度'">{{it.info.kqwd}}°C</span>
						   <span class="data_number" v-if="item_label=='土壤温度'">{{it.info.trwd}}°C</span>
						   <span class="data_number" v-if="item_label=='空气湿度'">{{it.info.kqsd}}%</span>
						   <span class="data_number" v-if="item_label=='土壤湿度'">{{it.info.trsd}}%</span>
						   <span class="data_number" v-if="item_label=='光合有效辐射'">{{it.info.gh}}LUX</span>
						   <span class="data_number" v-if="item_label=='最近灌溉时间'">{{it.info.guangai_time}}</span>
						</div>
					</div>
				</div>
				
		      </el-carousel-item>
		    </el-carousel>
	  </div>
	  <!--春秋棚  -->
	 <div class="box">
	  		   <el-carousel  direction="vertical" :autoplay="true" :interval='5000'>
	  		      <el-carousel-item v-for="(item,index) in seasonList" :key='index' class="item" style="transform: scale(1);">
	  		        <div class="card"  v-for="(it,idx) in item" :key='idx'>
	  					<div class="title">
	  						<div class="t_l"></div>
	  						<div class="t_r">
	  							<div>{{it.yard_name}}</div>
	  							<div class="line"></div>
	  						</div>
	  					</div>
	  					<div class="card_data">
	  						<div v-for="(item_label,index_label) in labelList2" :key='index_label' class="data_item">
	  						   <div>{{item_label}}<i></i></div>
	  						   <span class="data_number" v-if="item_label=='空气温度'">{{it.info.kqwd}}°C</span>
	  						   <span class="data_number" v-if="item_label=='土壤温度'">{{it.info.trwd}}°C</span>
	  						   <span class="data_number" v-if="item_label=='空气湿度'">{{it.info.kqsd}}%</span>
	  						   <span class="data_number" v-if="item_label=='土壤湿度'">{{it.info.trsd}}%</span>
	  						</div>
	  					</div>
	  				</div>
	  				
	  		      </el-carousel-item>
	  		    </el-carousel>
	  </div>
	  <!--大田  -->
	  <div class="box">
	  		   <el-carousel  direction="vertical" :autoplay="true" :interval='5000'>
	  		      <el-carousel-item v-for="(item,index) in fieldsList" :key='index' class="item" style="transform: scale(1);">
	  		        <div class="card"  v-for="(it,idx) in item" :key='idx'>
	  					<div class="title">
	  						<div class="t_l bg3"></div>
	  						<div class="t_r">
	  							<div>{{it.yard_name}}</div>
	  							<div class="line"></div>
	  						</div>
	  					</div>
	  					<div class="card_data">
	  						<div v-for="(item_label,index_label) in labelList3" :key='index_label' class="data_item">
	  						   <div>{{item_label}}<i></i></div>
	  						   <span class="data_number" v-if="item_label=='空气温度'">{{it.info.kqwd}}°C</span>
	  						   <span class="data_number" v-if="item_label=='土壤温度'">{{it.info.trwd}}°C</span>
	  						   <span class="data_number" v-if="item_label=='空气湿度'">{{it.info.kqsd}}%</span>
	  						   <span class="data_number" v-if="item_label=='土壤湿度'">{{it.info.trsd}}%</span>
							   <span class="data_number" v-if="item_label=='风速'">{{it.info.fs}}m/s</span>
							   <span class="data_number" v-if="item_label=='光照强度'">{{it.info.gz}}KLux</span>
							   <span class="data_number" v-if="item_label=='雨量'">{{it.info.yuliang}}mm</span>
							   <span class="data_number" v-if="item_label=='大气压'">{{it.info.dqy}}Kpa</span>
	  						</div>
	  					</div>
	  				</div>
	  				
	  		      </el-carousel-item>
	  		    </el-carousel>
	  </div>
	  
	  
	</div>


</div>
</template>

<script>
import { getCumulativeElectric,getCoreMonitor} from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
export default {
  name: 'HomeRight',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      sunList1:[
		 // {type:1,name:'1号日光棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
		 // {type:1,name:'2号日光棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
		 // {type:1,name:'3号日光棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
		 // {type:1,name:'4号日光棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
	  ],
	  labelList1:['空气温度','土壤温度','空气湿度','土壤湿度','光合有效辐射','最近灌溉时间'],
	  seasonList1:[
	  		 // {type:1,name:'1号春秋棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
	  		 // {type:1,name:'2号春秋棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
	  		 // {type:1,name:'3号春秋棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
	  		 // {type:1,name:'4号春秋棚',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',gh:'150',time:1636447332},
	  ],
	  labelList2:['空气温度','土壤温度','空气湿度','土壤湿度'],
	  fieldsList1:[
		 // {type:1,name:'1号大田',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',fs:'3.6',gzqd:200,yl:'0.5',dqy:'40'},
		 // {type:1,name:'2号大田',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',fs:'3.6',gzqd:200,yl:'0.5',dqy:'40'},
		 // {type:1,name:'3号大田',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',fs:'3.6',gzqd:200,yl:'0.5',dqy:'40'},
		 // {type:1,name:'4号大田',kqwd:'25',trwd:'25',kqsd:'30',trsd:'25',fs:'3.6',gzqd:200,yl:'0.5',dqy:'40'},
	  ],
	  labelList3:['空气温度','土壤温度','空气湿度','土壤湿度','风速','光照强度','雨量','大气压'],
	  tableData:[
	  		  {name:'光伏发电设备',yx:'8',gz:'2'},
	  		  {name:'风机发电设备',yx:'4',gz:'0'},
			  {name:'性能设备',yx:'2',gz:'0'},
			  {name:'充电桩',yx:'0',gz:'0'},
	  ],
	  electricObj:{},
	  xAxisName1: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24] ,// x轴名称
	  chartsData1: [80,65,40,60,5,40,75,42,55,0,18,85,74,43,16,19,27,98,62,2,11,54,84,24,0], // 存储图表中折线的数据
	  chartsData2:[96,80,15,80,0,10,55,65,77,10,0,22,54,69,12,3,49,79,45,7,23,46,100,50,6], // 存储图表中折线的数据
	  timeId:[],//定时器集合
    }
  },
  computed:{
	  //计算让两个数据为一条走马灯切换内容
	  sunList:function(){
		  let arr=[]
		  let a=[]
		  for(let i=0;i<this.sunList1.length;i=i+2){
			  a=[]
			  a.push(this.sunList1[i])
			  if(this.sunList1[i+1]){
				 a.push(this.sunList1[i+1]) 
			  }
			  arr.push(a)
		  }
		  console.log(this.sunList1)
		  console.log(arr)
		  return arr
	  },
	  seasonList:function(){
	  		  let arr=[]
	  		  let a=[]
	  		  for(let i=0;i<this.seasonList1.length;i=i+2){
	  			  a=[]
	  			  a.push(this.seasonList1[i])
				  if(this.seasonList1[i+1]){
				  	 a.push(this.seasonList1[i+1])
				  }
	  			  arr.push(a)
	  		  }
	  		  console.log(this.seasonList1)
	  		  console.log(arr)
	  		  return arr
	  },
	  fieldsList:function(){
	  		  let arr=[]
	  		  let a=[]
	  		  for(let i=0;i<this.fieldsList1.length;i=i+2){
	  			  a=[]
	  			  a.push(this.fieldsList1[i])
				  if(this.fieldsList1[i+1]){
				  	 a.push(this.fieldsList1[i+1])
				  }
	  			  arr.push(a)
	  		  }
	  		  console.log(this.fieldsList1)
	  		  console.log(arr)
	  		  return arr
	  },
	  
  },
  mounted () {
  		 // 获取chart盒子的宽度
  		 this.fullWidth = `${document.querySelector('#myChart_electricQuantity').clientWidth}`// 默认值
  		 const that = this
  		 window.onresize = () => {
  		   return (() => {
  		     // window.fullWidth = document.documentElement.clientWidth
  		     window.fullWidth = document.querySelector('#myChart_electricQuantity').clientWidth
  		     that.fullWidth = window.fullWidth
  		   })()
  		 }
		console.log('mounted')
  		console.log(this.fullWidth)
		this.getProfile()// 首页今日累计用电量统计
		this.getList()//地块滚动数据
  },
  destroyed() {
  		  console.log('destroyed')
  		  console.log(this.timeId)
  		  // 清空该页面中所有的的定时器
  		  for(let i=0;i<this.timeId.length;i++){
  			   clearInterval(this.timeId[i])
  		  }
		  // 销毁图表实列，释放echarts资源
		  // echarts.init(document.getElementById('myChart_electricQuantity')).dispose();
		  echarts.dispose(this.myChart);
		  this.myChart = null
  },
  methods: {
	getProfile () {
	  // 获取表格数据
	  const data = {}
	  // 请求数据
	  getCumulativeElectric(data)
	    .then((res) => {
		  console.log("首页今日累计用电量统计")
	      console.log(res)
		  console.log(res.data)
		  this.electricObj=res.data
		  this.tableData=res.data.list
		  this.chartsData1=this.electricObj.use_kwh //用电量
		  this.chartsData2=this.electricObj.hair_kwh //发电量
		  this.drawLine();
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getList () {
	  // 获取地块滚动数据
	  const data = {}
	  getCoreMonitor(data)
	    .then((res) => {
		  console.log("获取地块滚动数据")
	      console.log(res)
		  console.log(res.data)
		  if(res.code===200){
			  res.data.forEach((item)=>{
				  if(item.yard_type==1){
					  this.sunList1.push(item)
				  }else if(item.yard_type==2){
					  this.seasonList1.push(item)
				  }else{
					  this.fieldsList1.push(item)
				  }
			  })
			  console.log(this.sunList1)
			  console.log(this.seasonList1)
			  console.log(this.fieldsList1)
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	//改变表格的斑马颜色
	TableRowClassName({row, rowIndex}) {
	  if (rowIndex%2==0) {
	    return 'normal';
	  } else if(rowIndex%2==1){
	    return 'statistics-warning-row';
	  }
	},
	// 用电量数据图表
    drawLine () {
    		   // 基于准备好的dom，初始化echarts实例
    		           this.myChart = echarts.init(document.getElementById('myChart_electricQuantity'));
    		           this.myChart.resize({
    		             width: this.$refs.chart.clientWidth,
    		             height: this.$refs.chart.clientHeight
    		           })
    		           // 指定图表的配置项和数据
    		           var option = {
    					   ////动画效果
    					   animationDuration: 10000,
    					   animationDurationUpdate: 3000,
    					   animationEasing: 'linear',
    					   animationEasingUpdate: 'linear',
    		               title: {
    		                   text: '今日发电量与用电量趋势图',
							   textStyle:{
							   	color: '#A5EEEB',
								fontWeight:400,
								fontSize:14
							   }
    		               },
						   color: ['#36CFC9', '#ED9845'],
						   legend: {
						     data: ['发电量', '用电量'],
							 textStyle:{
								  color: '#FFFFFF'
							 },
							 x: 'center' //居右显示
						   },
						   // 节点标签
    		               tooltip: {
    						   trigger: 'axis',
    						   axisPointer: {
    						     type: 'cross',
    						     label: {
    						       backgroundColor: '#6a7985'
    							   // backgroundColor: 'red'
    						     }
    						   },
							   backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							   borderColor:'gray',
							   textStyle:{
								align:'left',///标签内容左对齐
							   	color: '#FFFFFF'
							   },
							   
							   // 自定义节点标签内容
							   formatter(params){
								   var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
								   const Y = date.getFullYear() + '年'
								   const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
								   const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
								   let time= Y + M + D 
								   let str=time+'  '+params[0].axisValue+'时'+'<br>'
							       params.forEach((item, idx) =>{
									   str+=item.marker+' '+item.seriesName+' : '+item.data
									   str += idx === params.length -1? '': '<br/>'
							       })   
								    return str;
							    }
    					   },
						   toolbox: {
						     feature: {
						       saveAsImage: {
						   		show:true,
						        backgroundColor :'balck',
						   		pixelRatio: 3
						   	}
						     },
						   },
						   // 调整图表的坐标轴区域距离整个图表左右的间距
						   grid: {
						     left: '0%',
						     right: '2%',
						     bottom: '2%',
							 top:40,
						     containLabel: true
						   },
    		               xAxis: {
    						   data:this.xAxisName1,
							   type: 'category',
							   boundaryGap: false,
							   splitLine:{
								   show:false
							   },
							   // 设置坐标轴字体颜色和宽度
							   axisLine:{
								   lineStyle: {
								       color: '#FFFFFF'
								   }
							   }
    		               },
    		               yAxis: {
							   splitLine:{
							   	  show:false
							   },
							   // 设置坐标轴字体颜色和宽度
							   axisLine:{
							   		lineStyle: {
							   		    color: '#FFFFFF'
							   		}						   
							   }
						   },
    		               series: [
							{
    		                   name: '用电量',
    		                   type: 'line',
    						   data:this.chartsData1,
    						   smooth: false,//设置折线的平滑度
    						   lineStyle:{
    						   	color:'yellow',
    						   },
    						   itemStyle: {
    						       normal: {areaStyle: {type: 'default'}}    
    						   },
    						   areaStyle: {
    										 //改变区域渐变颜色 
    										normal: {
    										    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    										            offset: 0,
    										            color: 'rgba(237,152,69,1)'
    										        }, {
    										            offset: 1,
    										            color: 'rgba(237,152,69,0.2)'
    										        }]),            
    										}
    						   			},
    						   			itemStyle: {
    						   				normal: {
    						   					color: 'rgba(237,152,69,0.8)', //改变折线点的颜色
    						   					lineStyle: {
    						   						color: 'rgba(237,152,69,0.8)' ,//改变折线颜色
    						   					}
    						   				}
    						   			},
    		               },
						   {
						       name: '发电量',
						       type: 'line',
						       data:this.chartsData2,
						       smooth: false,//设置折线的平滑度
						       lineStyle:{
						       	color:'yellow',
						       },
						       itemStyle: {
						           normal: {areaStyle: {type: 'default'}}    
						       },
						       areaStyle: {
						       		//改变区域渐变颜色
						       		normal: {
						       		    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						       		            offset: 0,
						       		            color: 'rgba(54,207,201,1)'
						       		        }, {
						       		            offset: 1,
						       		            color: 'rgba(54,207,201,0.2)'
						       		        }]),            
						       		}				       										
						       },
						       itemStyle: {
						       	normal: {
						       		color: 'rgba(54,207,201,0.8)', //改变折线点的颜色
						       		lineStyle: {
						       			color: 'rgba(54,207,201,0.8)' ,//改变折线颜色
						       		}
						       	}
						       },									   
						   }
						]
    		           };
    		           // 使用刚指定的配置项和数据显示图表。
    		           this.myChart.setOption(option);
    				   
    				   //动态循环显示数据节点
    				   this.dataLableChange(this.myChart,option,1)
    },
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   // console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
	
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '年'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},


  },
  // 监听屏幕变化
  watch: {
    // 如果 `fullHeight ` 发生改变，这个函数就会运行
    fullHeight: function (val) {
      if (!this.timer) {
        this.fullWidth = val
        this.drawLine()
        console.log(val)
        this.timer = true
        const that = this
        setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
          that.timer = false
        }, 100)
      }
    }

  }
}
</script>
<style scoped>
  .home_right{
	width: 100%;
	height: 100%;
    color: #606266;
	overflow: hidden;
  }
  .l_top{
	  width: 100%;
	 /* height: 372px; */
	  height: calc(40% - 15px);
	  background-color: rgba(10,23,56,0.7);
	  margin-bottom: 30px;
	  /* margin-top: -12px; */
	  padding: 0 20px;
	  box-sizing: border-box;
  }
  .title{
	  width: 100%;
	  height: 42px;
	  display: flex;
	  align-items: flex-end;
	  aljustify-content: start;
	  text-align: left;
  }
  .t_l{
	  width: 33px;
	  height: 34px;
	  background: url(../../assets/jiankong/quan_o.png);
	  background-size: contain;
	  margin-right: 14px;
  }
  .bg2{
	  background: url(../../assets/jiankong/quan_b.png); 
	  background-size: contain;
  }
  .bg3{
  	  background: url(../../assets/jiankong/quan_y.png); 
	  background-size: contain;
  }
  .t_r{
	  width: calc(100% - 47px);
	  font-size: 16px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #BFF2F0;
	  height: 42px;
	  line-height: 42px;
  }
  .line{
	  width: 100%;
	  height: 1px;
	  /* background: #36CFC9; */
	  background: url(../../assets/jiankong/line.png) no-repeat;
	  background-size: contain;
  }
  .ny{
	  height:calc(100% - 82px) ;
	  display: flex;
	  justify-content: space-between;
	  /* align-items: center; */
	  margin-top: 20px;
  }
  .l_top_left{
	 width: 439px; 
	 height:100%;
  }
  .today{
	  width: 439px;
	  height: 40px;
	  /* background: #FB5C55; */
	  background: url(../../assets/jiankong/title_bg.png);
	  background-size: contain;
	  font-size: 23px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #BFF2F0;
	  line-height: 40px;
	  letter-spacing: 2px;
  }
  .today_num{
	  font-size: 26px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #FFFFFF;
	  margin-left: 20px;
  }
  .table{
	  /* height: 240px; */
	  height: calc(100% - 60px);
	  margin-top: 20px;
	  /* background-color: red; */
  }
  .blue{
	  color: #36CFC9;
  }
  .yellow{
	  color: #ED9845;
  }
  
  
  .l_top_right{
	  width: calc(100% - 500px);
	  height: 100%;
	  /* background-color: gold; */
  }
  .chart{
	  width: 100%;
	  /* height: 250px; */
	  height: calc(100% - 50px);
	  margin-top: 10px;
  }
  #myChart_electricQuantity{
     width: 100%;
    /* height: 250px; */
     height: 100%;
     box-sizing: border-box;
    /* background-color: magenta; */
   }
 
  .l_bottom{
	  width:100%;
	  height: calc(60% - 15px);
	  /* height: calc(100% - 402px);
	  min-height: 540px; */
	  position: relative;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
  }
  .box{
	 /* width: 380px;
	  height: 590px; */
	  width: calc(33% - 20px);
	  height: 100%;
  }
  .item{
	 /*  width: 380px;
	  height: 590px; */
	  /* background-color: rgba(10,23,56,0.7); */ 
	  width:100%;
	  height:100%;
	 
  }
  .card{
	 /* width: 380px;
	 height: 280px; */
	 width:100%;
	 height:calc(50% - 15px);
	 background-color: rgba(10,23,56,0.7);
	 margin-bottom: 30px;
	 padding:0 20px;
	 box-sizing: border-box;
	 overflow: auto;
  }
  .card:last-child{
	  margin-bottom: 0;
  }
  .card_data{
	 /* width: 300px; */
	  width: 100%;
	  /* height: 160px; */
	  height: calc(100% - 80px);
	  position: relative;
	  top: 0;
	  left: 50%;
	  transform: translateX(-50%);
	  margin-top: 20px;
	  /* background-color: red; */
	  
  }
  .data_item{
	  float: left;
	  margin-right: 40px;
	  font-size: 14px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #BFF2F0;
	  margin-bottom: 20px;
	  height: 16px;
	  display: flex;
	  align-items: center;
  }
  .data_item:nth-child(2n){
	  margin-right: 0;
  }
  .data_item div{
	  display: inline-block;
	  min-width: 60px!important;
	  text-align:justify;
	  height: 16px;
	  line-height: 16px;
	  
  }
  .data_item div i{
    display:inline-block;
	height: 1px;
    width:100%;
  }
  .data_number{
	  display: inline-block;
	  min-width: 50px;
	  margin-left: 12px;
	  font-size: 15px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #FFFFFF;
  }

  /deep/.el-carousel__indicators--vertical{
	  display: none;
  }
  
  /deep/ .el-table{
  	background-color: transparent;
  }
  /deep/.el-table__row.statistics-warning-row {
    background: #222C47;
    color: #FFFFFF;
  } 
 /deep/.el-table__row.normal {
    background:#19223F;
    color: #FFFFFF;
  }
  /deep/.el-table__row>td{
  	border: none;
  }
  /deep/.el-table th.gutter{
  	background-color: #173660;
  }
  /deep/.el-table th.is-leaf{
  	border: none;
  }
  /deep/.el-table__row{
	  /* margin-bottom: 5px; */
	  height: 30px!important;
	  border-bottom: 5px solid #FFFFFF !important;
  }
  /deep/.el-table td, .el-table th.is-leaf{
	   height: 30px!important;
  }
  /* 去掉最下面的那一条线 */
  /deep/.el-table::before {
  	height: 0px;
  }
  /* 改变选中的颜色 */
  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td{
      background-color: #c6cfdf !important;
  }
  
  /* 走马灯切换动画 */
 /deep/ .el-carousel__item.is-animating {
      /* -webkit-transition: -webkit-transform 10s linear;
      transition: -webkit-transform 10s linear;
      transition: transform 10s linear;
      transition: transform 10s linear,-webkit-transform 10s linear; */
	   /* transition:all 5s linear */
  }
 /deep/.el-carousel--vertical{
	 height: 100%;
 }
 /deep/.el-carousel__container{
	 height: 100%;
 }
</style>



