<template>
<!-- 农产品入库 -->
 <div class="product_storage">
   <!-- 头部 -->
   <div class="land_head">
    	<div class="land_title">农产品入库</div>
   	    <div class="back" @click="back">
   		   <i class="el-icon-back"></i>
   		   <span style="margin-left: 4px;">返回</span>
   	    </div>
   </div>
   <!-- 入库信息填写 -->
   <div class="write_box">
	   <div class="box_left">
		   <el-select v-model="form.name" placeholder="品名选择" @change="nameChange">
		       <el-option
		         v-for="item in options"
		         :key="item.id"
		         :label="item.vegetables"
		         :value="item.id"
		   	   popper-class="select_tree"
		   	  >
		       </el-option>
		     </el-select>
		   		 <el-input
		   		    placeholder="请输入批号"
		   		    v-model="form.batch_number">
		   		 </el-input>
		   		 <el-input
		   		    placeholder="请输入单位"
					disabled
		   		    v-model="form.dw">
		   		 </el-input>
		   		 <el-input
		   		    placeholder="请输入规格"
					disabled
		   		    v-model="form.gg">
		   		 </el-input>
		   		 <el-date-picker
		   		       v-model="form.time"
		   		       type="date"
		   			   value-format="timestamp"
		   		       placeholder="选择日期">
		   		 </el-date-picker>
		   		 <el-input
		   		    placeholder="请输入数量"
		   		    v-model="form.num">
		   		 </el-input>
		   		 <el-select v-model="form.wz" placeholder="存储位置选择"  @change="positionChange">
		   		     <el-option
		   		       v-for="item in options_warehouse"
		   		       :key="item.id"
		   		       :label="item.warehouse"
		   		       :value="item.id"
		   		 	   popper-class="select_tree"
		   		 	  >
		   		     </el-option>
		   		 </el-select>
	   </div>
	   <div type="primary" class="add" @click="add" >
			<img src="../../../assets/del.png" />
			<span>添加</span>
	   </div>	 
   </div>
   <div class="table_container">
	   <div class="title">
	   	<div class="t_l"></div>
	   	<div class="t_r">
	   		<div>入库信息</div>
	   		<div class="line">
	   			<div class="line1">
	   				<div class="line3"></div>
	   			</div>
	   			<div class="line2">
	   				<div class="line4"></div>
	   			</div>
	   		</div>
	   	</div>
	   </div>
	   <el-table
	     :data="tableData"
	      size="mini"
	      fit
	   	  stripe
		  height="250"
	     :default-sort="{prop: 'date', order: 'descending'}"
	     :row-style="{height: '40px'}"
	     :cell-style="{padding: '0'}"
	     style="width: 100%"
		  @selection-change="handleSelectionChange"
	    >
		 <el-table-column type="selection" width="55"></el-table-column>
	   	 <el-table-column type="index" label="序号" width="100"></el-table-column>
	     <el-table-column prop="vegetables" label="品名" align="center" ></el-table-column>
	     <el-table-column prop="batch_number" label="批号" align="center" ></el-table-column>
	     <el-table-column prop="dw" label="单位" align="center" ></el-table-column>
	     <el-table-column prop="num" label="数量" align="center"></el-table-column>
	     <el-table-column prop="gg" label="规格" align="center" ></el-table-column>
	     <el-table-column prop="time" label="采集日期" align="center" style="height: 45px">
	     		<template slot-scope="scope">
	     			<div>{{timeFormate(parseInt(scope.row.time))}}</div>
	     		</template>
	     </el-table-column>
	     <el-table-column prop="warehouse" label="位置" align="center" ></el-table-column>
	      <el-table-column prop="operation" align="center" label="操作" >
	        <template slot-scope="scope">
	          <el-button
	            type="text"
	            size="small"
				class="out"
	   			style="color:#606266;"
	            @click="onDel(scope.row,scope.$index)"
	          >删 除</el-button>
	        </template>
	      </el-table-column>
	    </el-table>
	    <!-- 分页 -->
	    <!-- <el-row class="page">
	        <el-col>
	          <div class="pagination">
	            <el-pagination
	              v-if="paginations.total > 0"
	              @current-change="handleCurrentChange"
	              :current-page.sync="paginations.page_index"
	              :page-size="paginations.page_size"
	              layout="prev, pager, next, jumper"
	              :total="paginations.total">
	            </el-pagination>
	          </div>
	        </el-col>
	      </el-row> -->
   
  </div>
  <div type="primary" class="submit" @click='submit'>
  	<img src="../../../assets/del.png" />
  	<span>确认新增</span>
  </div>
  
</div>
</template>

<script>
import { getVegetablesList,getWarehouse,on_stock } from '@/api/index'
export default {
  name: 'ProductStorage',
  data () {
    return {
		isShow:false,
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      tableData: [
		  // {name:'西红柿',ph:'123456789',dw:'斤',sl:'50',gg:'50斤/袋',time:1636545084,wz:'冷藏库',bz:'请采摘手机后立即放入冷藏库',rkr:'王三一',zbr:'李西溪',num:3},
		  // {name:'西红柿',ph:'123456789',dw:'斤',sl:'50',gg:'50斤/袋',time:1636545084,wz:'冷藏库',bz:'请采摘手机后立即放入冷藏库',rkr:'王三一',zbr:'李西溪',num:3},
		
	  ],
      // 需要给分页组件传的信息
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 10 // 1页显示多少条
      },
	  form: {},
	  options: [],
	  options_warehouse:[],
	  multipleSelection: []	,//表格中选中的内容
	  u_id:''//用户id
    }
  },
  created () {
	console.log(this.$store.getters.user)
    this.getProfile1()  // 获取品类
	this.getProfile2()  // 获取仓库
	this.getCookie()
   
  },
  methods: {
	getProfile1() {
		console.log("获取品名列表")
	  // 获取表格数据
	  const data = {
	    type: '2',//1 分页 2不分页
		page: 1,
	    limit: '10',
	  }
	  console.log(data)
	  // 请求数据
	  getVegetablesList(data)
	    .then((res) => {
	          console.log(res)
	  		  console.log(res.data)
	  		  this.options=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getProfile2() {
		console.log("获取仓库列表")
	  // 获取表格数据
	  const data = {
	    type: '2',//1 分页 2不分页
		page: 1,
	    limit: '10',
	  }
	  console.log(data)
	  // 请求数据
	  getWarehouse(data)
	    .then((res) => {
	      console.log(res)
	  	  console.log(res.data)
	  	  this.options_warehouse=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getStorage(listData) {
		console.log("提交入库单")
	  // 获取表格数据
	  const data = {
	    list: JSON.stringify(listData),
		u_id:this.$store.getters.user.id?this.$store.getters.user.id:this.u_id
	  }
	  console.log(data)
	  // 请求数据
	  on_stock(data)
	    .then((res) => {
	      console.log(res)
	  	 // 操作成功
	  	 if (res.code === 200) {
	  	   this.$message({
	  	     message: '操作成功！',
	  	     type: 'success'
	  	   })
	  	   this.back()
	  	 }
	  	 
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	//读取cookie中的用户id
	getCookie () {
	 if (document.cookie.length>0) {
	  this.checked=true //证明内存中有保存密码
	  var arr=document.cookie.split('; ');//这里显示的格式需要切割一下自己可输出看下
	  for(var i=0;i<arr.length;i++){
	   var arr2=arr[i].split('=');//再次切割
	   //判断查找相对应的值
	   if(arr2[0]=='styUserId'){
	    this.u_id=arr2[1];//保存到保存数据的地方
	   }
	   console.log(this.u_id)
	  }
	 }
	},
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 品名选择框事件
	nameChange(value) {
	    console.log(value);
		let obj = {};
		obj = this.options.find((item)=>{
		    return item.id === value;
		});    
		console.log(obj.vegetables);
		this.form.vegetables=obj.vegetables
		this.form.v_id=obj.id
		this.form.dw=obj.unit
		this.form.gg=obj.spec
	
	},
	// 位置选择框事件
	positionChange(value) {
	    console.log(value);
		let obj = {};
		obj = this.options_warehouse.find((item)=>{
		    return item.id === value;
		});    
		console.log(obj.warehouse);
		this.form.warehouse=obj.warehouse
		this.form.w_id =obj.id
	},
	// 添加入库记录
	add(){
		console.log('添加出库记录')
		console.log(this.form)
		var retNum = /^\d*$/
		if(retNum.test(this.form.num)&&this.form.num>0){
		  if(this.form.name&&this.form.batch_number&&this.form.time&&this.form.wz){
			  this.form.time=this.form.time/1000
			  this.tableData.push(this.form)
			  this.form={}
		  }else{
			  this.$message({
			    message: '提交信息必须全部填写！',
			    type: 'error',
			    offset:400
			  })
		  }
		}else{
		   this.$message({
		     message: '入库数量必须填写大于0的数字！',
		     type: 'error',
		     offset:400
		   })
		   this.form.num=''
		}
	
	},
	// 删除
    onDel (row,index) {
	  console.log('删除')
      console.log(row,index)
	  this.tableData.splice(index,1)
    },
	// 确认新增入库单
	submit(){
		console.log(this.multipleSelection)
		if(this.multipleSelection.length>0){
			console.log('提交')
			// let obj={}
			let listData=[]
			this.multipleSelection.forEach(function(item){
				console.log(item)
				let obj={}
				obj.w_id=item.w_id
				obj.v_id=item.v_id
				obj.num=item.num
				obj.batch_number=item.batch_number
				listData.push(obj)
			})
			console.log(listData)
			this.getStorage(listData)
		}else{
			this.$message({
			   message: '请勾选需要提交的数据！',
			   type: 'error',
			   offset:400
			})
		}
		
		
	},
	// 勾选框选中情况
	handleSelectionChange(val) {
		console.log('勾选框选中情况')
	    this.multipleSelection = val;
		console.log(this.multipleSelection)
	},
   //  handleCurrentChange (page) {
   //    const number = String(page)
	  // this.pagesNumber=number
	  // console.log("切换至"+this.pagesNumber+"页")
   //    // 当点击切换分页数据时，发送请求给服务器，让其把相应的页码数据内容发送回来
	  // const data = {
	  //   page: number,
	  //   limit: '15',
	  // }
   //    console.log(data)
   //    // 发送请求
   //    // 请求数据
   //    getWordList(data)
   //      .then((res) => {
   //        console.log(res)
   //    	  console.log(res.data)
		 //  this.tableData=res.data
   //      })
   //      .catch((err) => {
   //        console.log(err)
   //      })
   //  },
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},

  },
  // 监听屏幕变化
  // watch: {
  //   // 如果 `fullHeight ` 发生改变，这个函数就会运行
  //   fullHeight: function (val) {
  //     if (!this.timer) {
  //       this.fullHeight = val
  //       this.changePageSize(this.fullHeight)
  //       this.getProfile()
  //       console.log(val)
  //       console.log(this.paginations.page_size)
  //       this.timer = true
  //       const that = this
  //       setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
  //         that.timer = false
  //       }, 100)
  //     }
  //   }

  // }
}
</script>
<style scoped>
  .product_storage{
	width: 100%;
	height: 100%;
	padding:0 30px 30px 30px;
	box-sizing: border-box;
	overflow: auto;
  }
  .land_head{
  	  width: 100%;
  	  height: 78px;
  	  margin-bottom: 30px;
  	  display: flex;
  	  align-items: flex-end;
  	  justify-content: start;
  	  position: relative;
  }
  .land_title{
  	  width: 554px;
  	  height: 78px;
  	  line-height: 78px;
  	  font-size: 28px;
  	  font-family: PingFang SC;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  margin-left: 100px;
  	  letter-spacing: 2px;
  	  background: url(../../../assets/title_bg.png) no-repeat center;
	  background-size: contain;
	  position: absolute;
	  left: 50%;
	  transform: translateX(-50%);
  }
  .back{
  	  position: absolute;
  	  right: 0;
  	  bottom:0;
  	  width: 135px;
  	  height: 48px;
  	  line-height: 48px;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  letter-spacing: 2px;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	 background: url(../../../assets/back_bg.png) no-repeat center;
  	 background-size: contain;
  	  /* border: 1px solid #4B5052; */
  	  display: flex;
  	  align-items: center;
  	  justify-content: center;
	  cursor: pointer;
  }
  .back i{
  	  font-size: 18px;
  }
  .write_box{
	  width: 100%;
	  height: 48px;
	  /* background: rgba(10,23,56,0.7); */
	  margin-bottom: 30px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  
  }
  .box_left{
	 width: 85%;
	 height: 48px;
	 /* background-color: yellow; */
	 display: flex;
	 align-items: center;
	 justify-content: space-between; 
  }
  .product_storage .table_container{
	width: 100%;
	height: calc(100% - 250px);
	background: rgba(10,23,56,0.7);
    padding:0px 20px 20px 20px;
	box-sizing: border-box;
  }
  .title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: flex-end;
      aljustify-content: start;
      text-align: left;
  	  margin-top: 0;
	  margin-bottom: 2%;
    }
    .t_l{
    	  width: 33px;
    	  height: 34px;
    	 background: url(../../../assets/jiankong/quan_o.png);
    	 background-size: contain;
    	  margin-right: 14px;
    }
    .t_r{
    	  width: calc(100% - 47px);
    	  font-size: 16px;
    	  font-family: PingFang SC;
    	  font-weight: bold;
    	  color: #BFF2F0;
    	  height: 42px;
    	  line-height: 42px;
    }
    .line{
    	  width: 100%;
    	  height: 1px;
    	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
    }
    .line1{
  	  width: 84%;
  	  height: 1px;
  	  background: #535A6B;
    }
    .line2{
  	  width: 15%;
  	  height: 1px;
  	  background: #535A6B;
  	  text-align: center;
    }
    .line3{
  	width: 90%;
  	height: 1px; 
  	  background: #36CFC9;
    }
    .line4{
  	  position: relative;
  	  left: 50%;
  	  transform: translateX(-50%);
  	  width: 15px;
  	  height: 1px;
  	  background: #36CFC9;
    }
	.out{
		width: 60px;
		height: 24px!important;
		line-height: 24px!important;
		background: rgba(215,140,71, 0.2);
		border: 1px solid #D78C47;
		color: #D78C47!important;
		padding: 0;
	}
  .product_storage .page{
    width: 50%;
    margin: 20px auto;
    text-align: center;
  }
  /deep/.el-table{
	  /* height: 250px!important; */
	  height: calc(100% - 90px)!important;
	  overflow: auto;
  }
  /*为了设置每行的高度不会随着内容的增多而变大设置的样式，需要结合表格行内样式的row-style、cell-style一起来使用*/
  /deep/ .el-table .cell{
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  /deep/ .el-table::before{
	  background-color: rgba(255,255,255,0);
  }
  /*表格最外层透明*/
  /deep/ .el-table, /deep/ .el-table__expanded-cell{
    background-color: transparent;
  }
  /deep/.el-table th, .el-table tr{
	  background-color: rgba(4,33,95,0.9)!important;
	  color: #FFFFFF;
	  padding: 0;
	  height: 40px!important;
  }
  /deep/.el-table--enable-row-transition .el-table__body td{
	 /* background: #0B1326; */
	 background-color: rgba(11,19,38,0.9);
	 color: #FFFFFF;
  }
  /deep/.el-table tbody tr:hover>td { 
  	 /* background:rgba(54,207,201,0.6); */
	background:rgba(12,15,16,0.8);
  	 color: #FFFFFF;
  }
  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
	  /* background:rgba(12,15,16,0.8); */
	  /* background:  rgba(4,33,95,0.9); */
	  background-color: rgba(11,19,38,0.9);
	  color: #FFFFFF;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
	  background-color: #36CFC9;
	  border-color: #36CFC9;
  }
  /deep/.el-select .el-input__inner{
  	  height: 48px;
  	  width: 150px;
  	  background-color: rgba(11,19,38,0.5);
  	  border: 1px solid #36CFC9;
  	  color: #FFFFFF !important;
  }
  /deep/.el-input.is-disabled .el-input__inner{
	  height: 48px;
	  width: 150px;
	  background-color: rgba(11,19,38,0.5);
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner{
	  border: 1px solid #36CFC9;
  }
  /deep/.el-input{
	 width: 150px!important; 
  }
  /deep/.el-input__inner{
	  height: 48px;
	  width: 150px;
	  background-color: rgba(11,19,38,0.5);
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
  /deep/.el-input__suffix{
	  color: #FFFFFF !important;
  }
  /deep/.el-pagination__jump{
  	color: #FFFFFF;
  }
  /deep/.el-pager li{
	  width: 40px;
	  height: 40px;
	  line-height: 40px;
	  background: #0C0F10;
	  border: 1px solid #4B5052;
	  color: #FFFFFF;
	  padding: 0;
	  margin: 0 10px;
  }
  /deep/.el-pagination .btn-next, .el-pagination .btn-prev{
	 width: 40px;
	 height: 40px;
	 line-height: 40px;
	 background: #0C0F10;
	 border: 1px solid #4B5052;
	 color: #FFFFFF;
	 padding: 0; 
  }
  /deep/.el-pagination button:disabled{
	width: 40px;
	height: 40px;
	line-height: 40px;
	background: #0C0F10;
	opacity: 0.5;
	border: 1px solid #4B5052;
	color: #FFFFFF;
	padding: 0;  
  }
  /deep/.el-pagination__jump{
	  height: 40px!important;
	  line-height: 40px!important;
  }
  /deep/.el-pagination__editor.el-input .el-input__inner{
	  width: 80px;
	  border-color: #FFFFFF
  }
  /deep/.el-pager li.active{
	  background: #0B1326;
	  /* background: rgba(54, 207, 201, 0.2); */
	  border: 1px solid #36CFC9;
	  font-weight: bold;
  }
  /deep/.el-pagination .btn-prev{
	 width: 40px;
	 height: 40px;
	 line-height: 40px;
	 background: #0C0F10;
	 opacity: 0.5;
	 border: 1px solid #4B5052;
	 color: #FFFFFF;
	 padding: 0; 
  }
 .submit{
 	  width: 108px;
 	  height: 48px;
 	  background: #0B1326;
 	  border: 1px solid #36CFC9;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
	  position: relative;
	  left: 50%;
	  top: 20px;
	  transform: translateX(-50%);
 }
.add{
	width: 108px;
	height: 48px;
	background: url(../../../assets/back_bg.png) no-repeat center;
	background-size: cover;
	border: 1px solid #36CFC9;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	right: 0;
}
.add img{
	margin-right: 5px;
}
</style>
<style>
/* // 级联选择器样式 */
.el-select-dropdown {
    background-color: rgba(11,19,38);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
   /* font-weight: 400; */
    color: #fff!important;
    line-height: 20px;
    border: none;
    }
.el-select-dropdown__item{
	color: #fff!important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
	background-color: rgba(0,0,0,1);
}
.el-select-dropdown__item.selected{
	color: #36CFC9!important;
}
</style>
