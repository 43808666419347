<template>
<div class="home_left">
	<div class="l_top">
		<div class="title">
			<div class="t_l"></div>
			<div class="t_r">
				<div>气象环境监测</div>
				<div class="line"></div>
			</div>
		</div>
		<div class="weather">
			<iframe scrolling="no" src="https://tianqiapi.com/api.php?style=ts&skin=pitaya&color=FFFFFF" frameborder="0" width="100%" height="90%" allowtransparency="true"></iframe>
		</div>
		<div class="data_box">
			<div class="one">
				<div class="item">
					<div class="label">温度</div>
					<div class="number">{{weatherObj.device_value.value1}}°C</div>
					<!-- <div class="number">{{weatherObj.value1}}°C</div> -->
				</div>
				<div class="data_line"></div>
				<div class="item">
					<div class="label">光照强度</div>
					<div class="number">{{weatherObj.device_value.value5}}KLux</div>
				</div>
				<div class="data_line"></div>
				<div class="item">
					<div class="label">湿度</div>
					<div class="number">{{weatherObj.device_value.value2}}%</div>
				</div>
				<div class="data_line"></div>
				<div class="item">
					<div class="label">雨量</div>
					<div class="number">{{weatherObj.device_value.value6}}mm</div>
				</div>
			</div>
			<div class="two">
				<div class="item">
					<div class="label">风速</div>
					<div class="number">{{weatherObj.device_value.value3}}m/s</div>
				</div>
				<div class="data_line"></div>
				<div class="item">
					<div class="label">大气压</div>
					<div class="number">{{weatherObj.device_value.value7}}Kpa</div>
				</div>
				<div class="data_line"></div>
				<div class="item">
					<div class="label">PM 2.5</div>
					<div class="number">{{weatherObj.device_value.value8}}μg/m³</div>
				</div>
				<div class="data_line"></div>
				<div class="item">
					<div class="label">PM 10</div>
					<div class="number">{{weatherObj.device_value.value9}}μg/m³</div>
				</div>
			</div>
		</div>
		<!-- <iframe allowtransparency="true" frameborder="0" width="437" height="166" scrolling="no" src="//tianqi.2345.com/plugin/widget/index.htm?s=2&z=3&t=1&v=0&d=5&bd=0&k=&f=ffffff&ltf=009944&htf=cc0000&q=1&e=0&a=1&c=54511&w=437&h=166&align=left" ></iframe> -->
		 
	</div>
	<div class="l_bottom">
		<div class="title">
			<div class="t_l"></div>
			<div class="t_r">
				<div>农业用地数据监测</div>
				<div class="line"></div>
			</div>
			<div class="more" @click="goLand">
				<span>查看详情</span>
				<div class="jiantou"></div>
			</div>
		</div>
		<!-- <div class="b_pic"></div> -->
		<img src="../../assets/map/ydsj.jpg" class="b_pic"/>
		<!-- <div class="b_box">
			<div v-for="(item,index) in landList" :key='index' class="land_item">
				<img src="../../assets/pic_1.png" v-if="item.type==1" />
				<img src="../../assets/pic_2.png" v-if="item.type==2" />
				<img src="../../assets/pic_3.png" v-if="item.type==3" />
				<span>{{item.name}}</span>
			</div>
		</div> -->
	</div>


</div>
</template>

<script>
import { getMeteorologicalMonitor } from '@/api/index'
export default {
  name: 'HomeLeft',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      landList:[
		  {type:1,name:'1号日光棚'},
		  {type:1,name:'2号日光棚'},
		  {type:2,name:'1号春秋棚'},
		  {type:2,name:'2号春秋棚'},
		  {type:3,name:'1号大田'},
		  {type:3,name:'2号大田'},
	  ],
	  weatherObj:{
		  device_value:{
			  value1:''
		  }
	  },
    }
  },
  created () {
    this.getWeather()//获取气象环境监测
    // 获取屏幕高度
    this.fullHeight = `${document.documentElement.clientHeight}`// 默认值
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
  },
  methods: {
	getWeather () {
	  // 获取表格数据
	  const data = {
	    device_model: '',//型号
	    yard_id: ''//场地
	  }
	  // 请求数据
	  getMeteorologicalMonitor(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  console.log('气象数据')
		  this.weatherObj=res.data
		  this.$forceUpdate()
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
    //农业用地数据监控
	goLand(){
		this.$router.push('/agriculturalLandDataMonitoring')
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '-'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},


  },
  // 监听屏幕变化
  // watch: {
  //   // 如果 `fullHeight ` 发生改变，这个函数就会运行
  //   fullHeight: function (val) {
  //     if (!this.timer) {
  //       this.fullHeight = val
  //       this.changePageSize(this.fullHeight)
  //       this.getProfile()
  //       console.log(val)
  //       console.log(this.paginations.page_size)
  //       this.timer = true
  //       const that = this
  //       setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
  //         that.timer = false
  //       }, 100)
  //     }
  //   }

  // }
}
</script>
<style scoped>
  .home_left{
	width: 100%;
	height: 100%;
    color: #606266;
	overflow: hidden;
  }
  .l_top{
	  width: 100%;
	  /* height: 372px; */
	  height: calc(40% - 15px);
	  background-color: rgba(10,23,56,0.7);
	  margin-bottom: 30px;
	  /* margin-top: -12px; */
	  padding: 0 20px;
	  box-sizing: border-box;
  }
  .title{
	  width: 100%;
	  height: 42px;
	  display: flex;
	  align-items: flex-end;
	  aljustify-content: start;
	  text-align: left;
  }
  .t_l{
	  width: 33px;
	  height: 34px;
	  /* background: #ED9845; */
	  background: url(../../assets/jiankong/quan_o.png);
	  margin-right: 14px;
	  background-size: contain;
  }
  .t_r{
	  width: calc(100% - 47px);
	  font-size: 16px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #BFF2F0;
	  height: 42px;
	  line-height: 42px;
  }
  .line{
	  width: 100%;
	  height: 1px;
	  background: url(../../assets/jiankong/line.png);
	  background-size: contain;
  }
  .weather{
	  width: 400px;
	 /* height: 200px; */
	  height: 150px;
	  position: relative;
	  left: 50%;
	  top: 3px;
	  transform: translateX(-50%);
  }
  .data_box{
	  width: 437px;
	  /* height: 142px; */
	  height: calc(100% - 200px);
	  overflow: auto;
	  border-top: 1px solid #535A6B;
	  padding: 10px 0;
	  box-sizing: border-box;
	  position: relative;
	  left: 50%;
	  transform: translateX(-50%);
  }
  .one,.two{
	  display: flex;
	  align-items: center;
	  justify-content: space-around;
  }
  .two{
	  margin-top: 15px;
  }
  .item{
	  width: 65px;
  }
  .label{
	  /* width: 24px; */
	  height: 12px;
	  font-size: 12px;
	  font-family: PingFang SC;
	  font-weight: 400;
	  color: #A5EEEB;
	  margin-bottom: 9px;
  }
  .number{
	  height: 12px;
	  font-size: 14px;
	  font-family: PingFang SC;
	  font-weight: 500;
	  color: #FFFFFF;
  }
  .data_line{
	  width: 1px;
	  height: 25px;
	  background: #535A6B;
  }
  
  
  .l_bottom{
	  width:100%;
	  /* min-height: 540px; */
	  /* height: calc(100% - 402px); */
	  height: calc(60% - 15px);
	  background-color: rgba(10,23,56,0.7);
	  position: relative;
	  padding: 0 20px;
	  box-sizing: border-box;
  }
  .more{
	  position: absolute;
	  display: flex;
	  align-items: center;
	  right: 20px;
	  top: 15px;
	  height: 14px;
	  font-size: 14px;
	  font-family: PingFang SC;
	  font-weight: 400;
	  color: #EDDEBF;
	  cursor: pointer;
  }
  .jiantou{
	  display: inline-block;
	  margin-left: 9px;
	  width: 14px;
	  height: 19px;
	  /* background: #E4D2B5; */
	  background: url(../../assets/jiankong/jiantou.png);
	  background-size: contain;
  }
  .b_pic{
	 width: 440px;
	 /* height: 60%; */
	 height: calc(100% - 90px);
	 margin-top: 20px;
  }
  .b_box{
	  width:320px ;
	  /* height: 100px; */
	  height: calc(40% - 100px);
	  overflow:auto;
	  position: relative;
	  left: 50%;
	  transform: translateX(-50%);
	  margin-top: 20px;
	  overflow: auto;
  }
  .land_item{
	  float: left;
	  width: 108px;
	  height: 18px;
	  margin-bottom: 15px;
	  font-size: 14px;
	  font-family: PingFang SC;
	  font-weight: 400;
	  color: #FFFFFF;
	  text-align: left;
	  margin-right: 90px;
  }
  .land_item:nth-child(2n){
	  margin-right: 0;
  }
  .land_item img{
	  width: 18px;
	  height: 18px;
	  margin-right: 9px;
  }
</style>


