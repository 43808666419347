// import { Message } from 'element-ui'
// import { getToken } from '@/utils/authToken' // 与后端的协商，websocket请求需要带上token参数
let websock = null
let messageCallback = null
let errorCallback = null
let wsUrl = '*'

let lockReconnect = false // 是否真正建立连接
// 心跳时间可以和后端人员协商确定
const timeout = 5 * 1000// 5秒一次心跳
let timeoutObj = null // 心跳心跳倒计时
let serverTimeoutObj = null // 心跳倒计时
let timeoutnum = null // 断开 重连倒计时

// 接收ws后端返回的数据
function websocketonmessage (e) {
  // 收到服务器信息，心跳重置
  reset()
  messageCallback(JSON.parse(e.data))
}

/**
 * 发起websocket连接
 * @param {Object} agentData 需要向后台传递的参数数据
 */
export function websocketSend (agentData) {
  // 加延迟是为了尽量让ws连接状态变为OPEN
  setTimeout(() => {
  // 添加状态判断，当为OPEN时，发送消息
    if (websock.readyState === websock.OPEN) { // websock.OPEN = 1
    // 发给后端的数据需要字符串化
      websock.send(JSON.stringify(agentData))
    }
    if (websock.readyState === websock.CLOSED) { // websock.CLOSED = 3
      console.log('websock.readyState=3')
      console.log('ws连接异常，请稍候重试')
      errorCallback()
    }
  }, 100)
}

// 关闭ws连接
function websocketclose (e) {
  // e.code === 1000  表示正常关闭。 无论为何目的而创建, 该链接都已成功完成任务。
  // e.code !== 1000  表示非正常关闭。
  if (e && e.code !== 1000) {
    console.log(e.code)
    console.log(e.reason)
    console.log(e.wasClean)
    console.log('ws连接异常，请稍候重试')
    // 重连
    reconnect()
    errorCallback()
  }
}

// 建立ws连接
function websocketOpen (e) {
  console.log('ws连接成功')
  // 开启心跳
  start()
}

// 重新连接
function reconnect () {
  if (lockReconnect) {
    return
  };
  lockReconnect = true
  // 没连接上会一直重连，设置延迟避免请求过多
  timeoutnum && clearTimeout(timeoutnum)
  timeoutnum = setTimeout(function () {
    // 新连接
    initWebSocket()
    lockReconnect = false
  }, 5000)
}

// 重置心跳
function reset () {
  // 清除时间
  clearTimeout(timeoutObj)
  clearTimeout(serverTimeoutObj)
  // 重启心跳
  start()
}

// 开启心跳
function start () {
  timeoutObj && clearTimeout(timeoutObj)
  serverTimeoutObj && clearTimeout(serverTimeoutObj)
  timeoutObj = setTimeout(function () {
    // 这里发送一个心跳，后端收到后，返回一个心跳消息，
    if (websock.readyState === 1) { // 如果连接正常
      const obj = {
        type: 'heartCheck',
        data: 'heart'
      }
      websock.send(JSON.stringify(obj))
      console.log('心跳信息发送')
    } else { // 否则重连
      reconnect()
    }
    serverTimeoutObj = setTimeout(function () {
      // 超时关闭
      websock.close()
    }, timeout)
  }, timeout)
}

// 初始化weosocket
function initWebSocket () {
  if (typeof (WebSocket) === 'undefined') {
    console.log('您的浏览器不支持WebSocket，无法获取数据')
    return false
  }
  // const token = 'JWT=' + getToken()
  // ws请求完整地址
  // const requstWsUrl = wsUrl + '?' + token

  const requstWsUrl = wsUrl
  websock = new WebSocket(requstWsUrl)

  websock.onmessage = function (e) {
    websocketonmessage(e)
  }
  websock.onopen = function () {
    websocketOpen()
  }
  websock.onerror = function () {
    console.log('ws连接异常，请稍候重试')
    // 重连
    reconnect()
    errorCallback()
  }
  websock.onclose = function (e) {
    websocketclose(e)
  }
}

/**
 * 发起websocket请求函数
 * @param {string} url ws连接地址
 * @param {Object} agentData 传给后台的参数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 * @param {function} errCallback ws连接错误的回调函数
 */
// export function sendWebsocket (url, agentData, successCallback, errCallback) {
//   wsUrl = url
//   initWebSocket()
//   messageCallback = successCallback
//   errorCallback = errCallback
//   websocketSend(agentData)
// }
export function sendWebsocket (url, successCallback, errCallback) {
  wsUrl = url
  initWebSocket()
  messageCallback = successCallback
  errorCallback = errCallback
}
/**
 * 发送消息的websocket函数
 */
export function sendMessageWebsocket (agentData, successCallback, errCallback) {
  websocketSend(agentData)
  messageCallback = successCallback
  errorCallback = errCallback
}

/**
 * 关闭websocket函数
 */
export function closeWebsocket () {
  if (websock) {
    websock.close() // 关闭websocket
    websock.onclose() // 关闭websocket
  }
}
