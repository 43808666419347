// 这个JS文件就是专门用于管理请求各种接口地址的
import Network from './network'

// 封装各种接口请求
// 登录
export const getLogin = (data) => Network.get('/api/Login/login', data)
// 仓库列表
export const getWarehouse = (data) => Network.get('/api/Stock/getWarehouse', data)
// 仓库增删改
export const doWarehouse = (data) => Network.get('/api/Stock/doWarehouse', data)
// 品名列表
export const getVegetablesList = (data) => Network.get('/api/Stock/getVegetablesList', data)
// 仓库增删改
export const doVegetables = (data) => Network.get('/api/Stock/doVegetables', data)
// 入库
export const on_stock = (data) => Network.get('/api/Stock/on_stock', data)
// 出库
export const out_stock = (data) => Network.get('/api/Stock/out_stock', data)
// 库存查询
export const getStockList = (data) => Network.get('/api/Stock/getStockList', data)
// 出入库记录
export const getStockLogList = (data) => Network.get('/api/Stock/getStockLogList', data)
// 出入库详情
export const getStockLogInfo = (data) => Network.get('/api/Stock/getStockLogInfo', data)
// excel导出出库单
export const excelOutStock = (data) => Network.get('/api/Stock/excelOutStock', data)
// 出入库今年库存统计
export const stock_count = (data) => Network.get('/api/Stock/stock_count', data)
// 当前库存统计
export const stock_now_count = (data) => Network.get('/api/Stock/stock_now_count', data)

// 场地筛选
export const getYardList = (data) => Network.get('/api/Clock/getYardList', data)
// 出勤记录
export const getClockInList = (data) => Network.get('/api/Clock/getClockInList', data)
// 出勤记录增删
export const doClockIn = (data) => Network.get('/api/Clock/doClockIn', data)
// 出勤记录树状图统计
export const getVolumeCount = (data) => Network.get('/api/Clock/getVolumeCount', data)
// 出勤记录线状图统计
export const getVolumeMonthCount = (data) => Network.get('/api/Clock/getVolumeMonthCount', data)

// 设备列表
export const getDeviceList = (data) => Network.get('/api/Device/getDeviceList', data)
// 获取设备详情列表
export const getDeviceInfoList = (data) => Network.get('/api/Device/getDeviceInfoList', data)
// 设备详情
export const getDeviceInfo = (data) => Network.get('/api/Device/getDeviceInfo', data)

// 获取用户列表
export const getUserList = (data) => Network.get('/api/User/getUserList', data)
// 获取用户列表
export const doUser = (data) => Network.get('/api/User/doUser', data)

// 首页气象环境监测
export const getMeteorologicalMonitor = (data) => Network.get('/api/Monitor/getMeteorologicalMonitor', data)
// 首页今日累计用电量统计
export const getCumulativeElectric = (data) => Network.get('/api/Monitor/getCumulativeElectric', data)
// 首页地块监测列表
export const getCoreMonitor = (data) => Network.get('/api/Monitor/getCoreMonitor', data)


// 农业用地数据监测
// 获取设备状态
export const getNyDeviceList = (data) => Network.get('/api/Monitor/getDeviceList', data)
// 农业用地数据监测
export const getAgriculturalLandMonitor = (data) => Network.get('/api/Monitor/getAgriculturalLandMonitor', data)
// 实时数据监测
export const realtimeDeviceMonitor = (data) => Network.get('/api/Monitor/realtimeDeviceMonitor', data)
// 首页等地的能源数据监测(能源百分比你用发电和用电求一下)
export const energy_jiance = (data) => Network.get('/api/Record/energy_jiance', data)

// 综合数据分析
// 农业用地面积统计
export const yard_area = (data) => Network.get('/api/Record/yard_area', data)
// 农作物种植面积统计
export const vegetables_area = (data) => Network.get('/api/Record/vegetables_area', data)
// 农作物产值预算
export const vegetables_saturated = (data) => Network.get('/api/Record/vegetables_saturated', data)
// 获取预警信息
export const getWarningList = (data) => Network.get('/api/Record/getWarningList', data)
// 能源数据分析(当月累计用电量)
export const getMonthCumulativeElectric = (data) => Network.get('/api/Record/getMonthCumulativeElectric', data)
// 日光棚月度土壤温度/湿度/光合/二氧化碳
export const getRGMonthMonitor = (data) => Network.get('/api/Record/getRGMonthMonitor', data)
// 劳动人员出勤统计
export const getMonthClockCount = (data) => Network.get('/api/Clock/getMonthClockCount', data)
//新能源用电占比
export const new_Energy_Proportion = (data) => Network.get('/api/Record/new_Energy_Proportion', data)


//智能控制
//灌溉调控
export const irrigation_Regulation = (data) => Network.get('/api/Monitor/irrigation_Regulation', data)
//灌溉监测
export const irrigation_monitor = (data) => Network.get('/api/Monitor/irrigation_monitor', data)

//摄像头监控信息获取
export const getCameraUrl = (data) => Network.get('/api/Camera/getCameraUrl', data)
//获取设备参数名称
export const top_key = (data) => Network.get('/api/device/top_key', data)