<template>
<div class="add_operate">
  <div class="el-icon-close close" @click="dialog.show = false"></div>
  <div class="marks_top">
  	<div class="marks_name">{{dialog.title}}</div>
  </div>
  <div class="content">
	  <el-form
	    ref="form"
	    :model="form"
	    :rules="rules"
	  >
	   <el-form-item prop="clock_in_time" label="出勤时间">
	    	<el-date-picker
	    	      v-model="form.clock_in_time"
	    	      type="date"
	    		  value-format="timestamp"
				  
	    	      placeholder="选择日期时间">
	    	</el-date-picker>			  
	    </el-form-item>
	    <el-form-item prop="yard_id" label="地块">
	      <!-- <el-input type="text" v-model="form.dk" placeholder="请输入"></el-input> -->
		  <!-- <el-cascader
		      v-model="value"
		      :options="options"
		      :props="{value: 'id', label: 'land_name',children:'children', expandTrigger: 'hover' }"
		      @change="handleChange"
		  	clearable
		  	popper-class="train-tree">
		  </el-cascader> -->
		  <el-select v-model="form.yard_id" clearable  placeholder="请选择">
		      <el-option
		        v-for="item in options"
		        :key="item.id"
		        :label="item.yard_name"
		        :value="item.id">
		      </el-option>
		  </el-select>
	    </el-form-item>
	    
	    <el-form-item prop="people_num" label="人数">
	      <el-input type="text" v-model.number="form.people_num" placeholder="请输入"></el-input>
	    </el-form-item>
	    
	    <el-form-item prop="labour_h" label="每人劳动时长(小时)">
	      <el-input type="text" v-model.number="form.labour_h" placeholder="请输入"></el-input>
	    </el-form-item>
	    
	    <el-form-item prop="labour_volume" label="劳动量(人·小时）">
	      <el-input type="text" v-model="form.people_num * form.labour_h" placeholder="请输入" disabled></el-input>
	    </el-form-item>
	    
	  </el-form>
  </div>
  <div class="btn">
	  <div class="submit" @click="onSubmit('form')">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;">确认</span>
	  </div>
	  <div class="cancel" @click="dialog.show = false">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;" >取消</span>
	  </div>
  </div>
</div>
</template>

<script>
import { doClockIn,getYardList } from '@/api/index'
import axios from 'axios'
export default {
  name: 'DeleteRemain',
  props: {
    dialog: Object,
    form: Object,
	options:Array,
  },
  data () {
    return {
		value:'',
		rules: {
		          yard_id: [
		            { required: true, message: '请选择地块', trigger: 'change' },
		            // { min: 1, max:30, message: '长度在 3 到 5 个字符', trigger: 'blur' }
		          ],
				  people_num:[
				    { required: true, message: '请输入数量', trigger: 'blur' }
				  ],
				  labour_h:[
				    { required: true, message: '每人劳动时长(小时)', trigger: 'blur' }
				  ],	  
		          clock_in_time: [
		            { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
		          ],
				  // labour_volume:[
				  //   { required: true, message: '劳动量(人·小时）', trigger: 'blur' }
				  // ],	  
						  
		        },
    }
  },
  mounted () {
  },
  methods: {
	// 地块选择框事件
	handleChange(value) {
		let _this=this
	    console.log(value);
		this.form.dpid=value[0]
		this.form.dkid=value[1]
		this.options.forEach(function(element) {
              if(value[0]==element.id){
				  _this.form.dp=element.land_name
				  element.children.forEach(function(e) {
					  if(value[1]==e.id){
					   _this.form.dk=e.land_name
					   }
				  })
			  }
        })
		console.log(this.form)
		// 更新表格内容
	},
   //  // 提交按钮
    onSubmit (formName) {
	  console.log(this.form)
	  console.log(this.dialog)
	   this.$refs[formName].validate((valid) => {
	            if (valid) {
	              // 表单数据验证完成之后，提交数据;
	              const data = {
	                 act:'add',
	                 id:this.form.id,
	                 yard_id:this.form.yard_id,
	                 people_num:this.form.people_num,
	                 labour_h:this.form.labour_h,
	                 labour_volume:this.form.people_num*this.form.labour_h,
	                 clock_in_time:this.form.clock_in_time/1000
	              }
	              console.log(data)
	              doClockIn(data).then((res) => {  
	                console.log(res)
	                // 操作成功
	                if (res.msg === '成功') {
	                  this.$message({
	                    message: '操作成功！',
	                    type: 'success'
	                  })
	                  this.dialog.show = false
	                  this.$emit('update')
	                }
	              })
	                .catch((err) => {
	                  console.log(err)
	                })
	            } else {
	              console.log('error submit!!');
	              return false;
	            }
	});
	 
    },
	
	
  }
}
</script>

<style scoped>
.add_operate{
  width: 521px;
  height: 589px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: rgba(10, 23, 56, 0.7);
  border: 2px solid #36CFC9;
  text-align: center;
}
 .close{
	 position: absolute;
	 right: 15px;
	 top: 15px;
	 color: #FFFFFF;
	 font-size: 20px;
 }
 .marks_top{
 	width: 100%;
 	height: 77px;
 	border-left: 5px solid #36CFC9;
 	padding:15px 0  0 25px;
 	box-sizing: border-box;
 	/* margin-bottom: 30px; */
 }
 .marks_name{
 	width: 50%;
 	height:48px ;
 	line-height: 48px;
 	background-image: linear-gradient(to right, rgba(54,207,201,0.5) , rgba(255,255,255,0));
 	color: #FFFFFF;
 	font-size: 16px;
 	font-family: PingFang SC;
 	font-weight: bold;
 	text-align: left;
 	padding-left:20px;
 	box-sizing: border-box;
 	letter-spacing: 2px;
 }
 .content{
	 width: 521px;
	 height: 400px;
	 /* background-color: red; */
	 margin-bottom: 30px;
	 padding-left: 30px;
	 box-sizing: border-box;
	 /* font-size: 16px;
	 font-family: PingFang SC;
	 font-weight: bold; */
 }
 .btn{
	 display: flex;
	 align-items: center;
	 justify-content: center;
 }
 .submit{
 	  width: 108px;
 	  height: 48px;
 	  background: url(../../assets/back_bg.png) no-repeat center;
 	  background-size: cover;
 	  border: 1px solid #36CFC9;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
	  margin-right: 30px;
 }
 .cancel{
 	  width: 108px;
 	  height: 48px;
 	  background: url(../../assets/back_bg.png) no-repeat center;
 	  background-size: cover;
 	  border: 1px solid #4B5052;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
 }
 /deep/.el-form{
 	/* width: 450px!important; */
 	position: relative;
 	left: 50%;
 	transform: translateX(-50%);
 } 
 /deep/.el-form-item__label{
 	 height: 40px;
 	 width: 162px!important;
 	 color: #FFFFFF;
 	 text-align: justify;
  }
  /deep/.el-form-item label:after {
          content: "";
          display: inline-block;
          width: 100%;
      }    
 /deep/.el-form-item.is-required .el-form-item__label:before {
          content: none !important;
      }
/deep/.el-input{
	width: 301px!important;
	height: 48px;
	margin-bottom: 15px;
}
/deep/.el-input__suffix{
	right: 50px;
}
/deep/.el-form-item__error{
	top:50px;
	left: 200px;
}
/deep/.el-input.is-disabled .el-input__inner{
	background-color: transparent;
	border: 1px solid #36CFC9;
}
/deep/.el-input--prefix .el-input__inner{
	margin-left: 36px;
}
/deep/.el-input__prefix{
	left: 40px!important;
}
/deep/ .el-select .el-input__inner{
	/* width: 301px!important; */
	width: 220px!important;
	height: 48px;
}
/deep/.el-select{
	margin-right: 0!important;
}
</style>
