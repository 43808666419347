<template>
<div class="add_operate">
  <div class="el-icon-close close" @click="close"></div>
  <div class="marks_top">
  	<div class="marks_name">{{dialog.title}}</div>
  </div>
  <div class="content">
	  <el-form
	    ref="form"
	    :model="form"
	    :rules="rules"
	  >
	   
	    <el-form-item label="品名">
	      <el-input type="text" v-model="form.vegetables" placeholder="请输入" disabled></el-input>
	    </el-form-item>
	    
		<el-form-item  label="在库数量">
		  <el-input type="text" v-model="form.stock" placeholder="请输入" disabled></el-input>
		</el-form-item>
		
	    <el-form-item prop="num1" label="西单">
	      <el-input type="text" v-model.number="form.num1" placeholder="请输入"></el-input>
	    </el-form-item>
	    
	    <el-form-item prop="num2" label="银座">
	      <el-input type="text" v-model.number="form.num2" placeholder="请输入"></el-input>
	    </el-form-item>
		
		<el-form-item prop="num3" label="未来城">
		  <el-input type="text" v-model.number="form.num3" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num4" label="白广路">
		  <el-input type="text" v-model.number="form.num4" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num5" label="都城">
		  <el-input type="text" v-model.number="form.num5" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num6" label="电力医院">
		  <el-input type="text" v-model.number="form.num6" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num7" label="英大">
		  <el-input type="text" v-model.number="form.num7" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num8" label="物资">
		  <el-input type="text" v-model.number="form.num8" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num9" label="国际">
		  <el-input type="text" v-model.number="form.num9" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num10" label="中再">
		  <el-input type="text" v-model.number="form.num10" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num11" label="通航">
		  <el-input type="text" v-model.number="form.num11" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num12" label="国康">
		  <el-input type="text" v-model.number="form.num12" placeholder="请输入"></el-input>
		</el-form-item>
		
		<el-form-item prop="num13" label="西局">
		  <el-input type="text" v-model.number="form.num13" placeholder="请输入"></el-input>
		</el-form-item>
	    
	  </el-form>
  </div>
  <div class="btn">
	  <div class="submit" @click="onSubmit('form')">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;">确认</span>
	  </div>
	  <div class="cancel" @click="close">
	  	<img src="../../assets/del.png" />
	  	<span style="margin-left: 8px;" >取消</span>
	  </div>
  </div>
</div>
</template>

<script>
import { addStudent } from '@/api/index'
import axios from 'axios'
export default {
  name: 'OutOperate',
  props: {
    dialog: Object,
    form: Object,
  },
  data () {
    return {
		rules: {
		          num1: [
		            { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
		          ],
				  num2: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num3: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num4: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num5: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num6: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num7: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num8: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num9: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num10: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ],  
				  num11: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ], 
				  num12: [
				    { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				  ], 
				   num13: [
				     { type:'number', required: true,message: '必须为数字', trigger: 'blur' },
				   ],  
				
						  
		},
    }
  },
  mounted () {

  },
  methods: {
	// 关闭
	close(){
		this.dialog.show = false
		this.form={}
	},  
   //  // 提交按钮
    onSubmit (form) {
	  console.log(this.form)
	  console.log(this.dialog)
	  this.$refs[form].validate(valid => {
	    if (valid) {
	      // 表单数据验证完成之后，提交数据;
		  this.form.total=this.form.num1+this.form.num2+this.form.num3+this.form.num4+this.form.num5+this.form.num6+this.form.num7+this.form.num8+this.form.num9+this.form.num10+this.form.num11+this.form.num12+this.form.num13
		  if(this.form.total<=this.form.stock){
			  this.dialog.show = false
			  this.$emit('update',this.form)
		  }else{
			  this.$message({
			    message: '出库数量不能大于产品总在库数量！',
			    type: 'error',
			    offset:400
			  })
		  }
		  
		}
	 })
	 
    },
	
	
  }
}
</script>

<style scoped>
.add_operate{
  width: 400px;
  height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: rgba(10, 23, 56, 0.7);
  border: 2px solid #36CFC9;
  text-align: center;
}
 .close{
	 position: absolute;
	 right: 15px;
	 top: 15px;
	 color: #FFFFFF;
	 font-size: 20px;
 }
 .marks_top{
 	width: 100%;
 	height: 77px;
 	border-left: 5px solid #36CFC9;
 	padding:15px 0  0 25px;
 	box-sizing: border-box;
 	/* margin-bottom: 30px; */
 }
 .marks_name{
 	width: 50%;
 	height:48px ;
 	line-height: 48px;
 	background-image: linear-gradient(to right, rgba(54,207,201,0.5) , rgba(255,255,255,0));
 	color: #FFFFFF;
 	font-size: 16px;
 	font-family: PingFang SC;
 	font-weight: bold;
 	text-align: left;
 	padding-left:20px;
 	box-sizing: border-box;
 	letter-spacing: 2px;
 }
 .content{
	 /* width:380px; */
	 height: 420px;
	 overflow: auto;
	 /* background-color: red; */
	 margin-bottom: 30px;
	 padding-left: 30px;
	 box-sizing: border-box;
	
 }
 .btn{
	 display: flex;
	 align-items: center;
	 justify-content: center;
 }
 .submit{
 	  width: 108px;
 	  height: 48px;
 	  background: url(../../assets/back_bg.png) no-repeat center;
 	  background-size: cover;
 	  border: 1px solid #36CFC9;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
	  margin-right: 30px;
 }
 .cancel{
 	  width: 108px;
 	  height: 48px;
 	 background: url(../../assets/back_bg.png) no-repeat center;
 	 background-size: cover;
 	  border: 1px solid #4B5052;
 	  font-size: 14px;
 	  font-family: PingFang SC;
 	  font-weight: bold;
 	  color: #FFFFFF;
 	  display: flex;
 	  justify-content: center;
 	  align-items: center;
 	  cursor: pointer;
 }
 /deep/.el-form{
 	width: 280px!important;
 } 
 /deep/.el-form-item{
	 margin-bottom: 10px;
	 height: 40px;
	 line-height: 40px;
 }
 /deep/.mask .el-form-item__content{
	 height: 40px!important;
 }
 /deep/.el-form-item__label{
 	 height: 40px;
 	 width: 80px!important;
 	 color: #FFFFFF;
 	 text-align: justify;
  }
  /deep/.el-form-item label:after {
          content: "";
          display: inline-block;
          width: 100%;
      }    
 /deep/.el-form-item.is-required .el-form-item__label:before {
          content: none !important;
      }
/deep/.el-input{
	/* width: 301px!important; */
	width: 200px!important;
	height: 48px;
	margin-bottom: 15px;
}
/deep/.el-input__suffix{
	right: 30px;
}
/deep/.el-input.is-disabled .el-input__inner{
	background-color: transparent!important;
	border-color:#36CFC9 ;
	height: 40px;
	line-height: 40px;
}
/deep/.el-input__inner{
	height: 40px!important;
	line-height: 40px!important;
}
/deep/.el-form-item__error{
	top:10px;
	left: 60%;
}
</style>
