<template>
	<!-- 园区数据监控中心 -->
  <div class="data_monitoring">
	<div class="left">
		<HomeLeft ></HomeLeft>
	</div>
	<div class="right">
		<HomeRight></HomeRight>
	</div>
  </div>
</template>

<script>
import HomeLeft from '../../components/DataMonitoring/HomeLeft.vue'
import HomeRight from '../../components/DataMonitoring/HomeRight.vue'
export default {
  name: 'DataMonitoring',
  data(){
	  return{
		 
	  }
  },
  components: {HomeLeft,HomeRight },
  methods:{
	  
  }
}
</script>

<style scoped>
  .data_monitoring {
    width: 100%;
    height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  .left{
	 position: absolute;
	 top: 0px;
	 left: 0;
	 width: 510px;
	 height: 100%;
	 padding: 30px 0 30px 30px;
	 box-sizing: border-box;
  }
  .right{
	  position: absolute;
	 right: 0;
	 top: 0px;
	 width:calc(100% - 540px);
	 height: 100%;
	 padding: 30px 30px 30px 0px;
	 box-sizing: border-box;
  }

</style>